<!--  -->
<template>
    <div class="main" style="display:flex;justify-content: center;">
        <div style="width: 800px;" class="detail-content">
            <div id="content_0_detailContent" style="text-align: left;" class="detail-content" deep="4">
                <h1 style="text-align:center;text-indent:2em;">
                    广汽三菱汽车销售有限公司隐私政策
                </h1>
                <p style="text-indent:2em;">
                    <br data-filtered="filtered">
                </p>
                <p style="text-indent:2em;">
                    版本更新日期：2022年10月12日
                </p>
                <p style="text-indent:2em;">
                    版本生效日期：2022年10月12日
                </p>
                <p style="text-indent:2em;">
                    广汽三菱汽车销售有限公司及其关联公司（以下简称“广汽三菱”或“我们”）一贯重视并致力于保护消费者或潜在消费者（以下简称“您”）的个人信息和隐私。您在使用我们的产品或服务时，我们可能会收集、使用、共享、存储您的个人信息。我们对您个人信息的所有收集、使用、共享、存储活动都将按照相关法律法规及本《隐私政策》（以下简称“本政策”）的规定进行。如果广汽三菱在产品/服务应用平台、用户条款或其他有法律约束力的协议、合同中，就收集、使用、共享、存储您的个人信息与您进行了约定，则前述约定的适用优先于本政策；前述约定中未涉及的事项，仍然适用本政策。
                </p>
                <p style="text-indent:2em;">
                    我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息，<strong>在您使用我们的产品或服务前，请务必仔细阅读并理解本政策，尤其是其中加粗/下划线的条款。</strong>
                </p>
                <p style="text-indent:2em;">
                    广汽三菱会在广汽三菱官方网站、产品或服务平台（如广汽三菱APP、广汽三菱MSPACE小程序、车载娱乐系统、车载T-BOX及后台系统，以下简称“服务平台”）上登载本政策。希望您在使用我们的产品/服务前仔细阅读并确认您已经充分理解本政策所载明的内容。<strong>当您使用或在我们更新本政策后继续使用（我们会及时提示您更新的情况）我们的产品或服务时，即视为您已经阅读、理解并同意本政策及其更新的版本，同意我们按照本政策的约定收集和使用您的个人信息。</strong>需要特别说明的是，广汽三菱可能包含跳转至其他网站的链接或指引。请注意，我们不控制这些第三方网站。<strong>本政策在任何情况下均不适用于其他第三方向您提供的服务，也不适用于广汽三菱平台中已另行独立设置法律声明及隐私政策的产品或服务，建议您在访问及使用其他第三方服务时仔细阅读他们的隐私政策。</strong>
                </p>
                <p style="text-indent:2em;">
                    本隐私政策将帮助您了解以下内容：
                </p>
                <p style="text-indent:2em;">
                    一、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                定义及适用范围
                </p>
                <p style="text-indent:2em;">
                    二、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                我们收集、使用个人信息的情形和目的
                </p>
                <p style="text-indent:2em;">
                    三、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                我们如何保护您的个人信息
                </p>
                <p style="text-indent:2em;">
                    四、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                您的权利（<strong>查询、编辑、更正、删除、撤回同意、注销</strong>）
                </p>
                <p style="text-indent:2em;">
                    五、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                我们如何共享、转让、公开披露您的个人信息
                </p>
                <p style="text-indent:2em;">
                    六、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                我们对Cookies和同类技术的使用
                </p>
                <p style="text-indent:2em;">
                    七、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                我们如何处理未成年人的个人信息
                </p>
                <p style="text-indent:2em;">
                    八、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                您的个人信息如何跨境转移
                </p>
                <p style="text-indent:2em;">
                    九、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                适用法律与争议解决
                </p>
                <p style="text-indent:2em;">
                    十、&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                本政策如何更新
                </p>
                <p style="text-indent:2em;">
                    十一、&nbsp;&nbsp;
                如何联系我们
                </p>
                <p style="text-indent:2em;">
                    <br data-filtered="filtered">
                </p>
                <p style="text-indent:2em;">
                    <strong>一、定义及适用范围</strong>
                </p>
                <p style="text-indent:2em;">
                    <strong>服务平台：</strong>指广汽三菱用来为客户提供产品或服务的网站和系统，主要包括：
                </p>
                <p style="text-indent:2em;">
                    （1）&nbsp;&nbsp;&nbsp;
                广汽三菱官方网站（ www.gmmc.com.cn）,简称“官网”
                </p>
                <p style="text-indent:2em;">
                    （2）&nbsp;&nbsp;&nbsp;
                广汽三菱手机客户端应用程序（广汽三菱App，菱行App）
                </p>
                <p style="text-indent:2em;">
                    （3）&nbsp;&nbsp;&nbsp; 广汽三菱车载娱乐系统、车载T-BOX及后台系统，简称“DA系统”
                </p>
                <p style="text-indent:2em;">
                    （4）&nbsp;&nbsp;&nbsp;
                广汽三菱官方商城微信小程序、广汽三菱MSPACE微信小程序
                </p>
                <p style="text-indent:2em;">
                    <strong>个人信息：</strong>指以电子或者其他方式记录的，能够单独使用或结合其他信息使用，识别特定自然人身份或者反应特定自然人活动情况的各种信息，不包括匿名化处理后的信息。
                </p>
                <p style="text-indent:2em;">
                    <strong>敏感个人信息：</strong>是指一旦泄露或者非法使用或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息，以及不满十四周岁的儿童个人信息。
                </p>
                <p style="text-indent:2em;">
                    <strong>设备信息&nbsp;：</strong>包括设备型号、设备制造商、唯一设备标识符（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）、IP、硬件序列号、SSID、任务信息、操作系统类型及型号、屏幕分辨率、电信运营商、软件安装列表、软件版本号、系统语言、存储容量等。
                </p>
                <p style="text-indent:2em;">
                    <strong>儿童：</strong>指不满十四周岁的未成年人。
                </p>
                <p style="text-indent:2em;">
                    <br data-filtered="filtered">
                </p>
                <p style="text-indent:2em;">
                    <strong>二、我们收集、使用个人信息的情形和目的</strong>
                </p>
                <p style="text-indent:2em;">
                    （一）在您使用我们的服务时，我们需要/可能需要收集和使用的您的个人信息有以下两种：
                </p>
                <p style="text-indent:2em;">
                    1、为实现我们向您提供服务的基本功能，您须授权我们收集、使用的必要信息。
                </p>
                <p style="text-indent:2em;">
                    2、为实现我们向您提供服务的附加功能，您可以授权我们收集、使用的信息。这些信息并非服务运行所必需，但这些信息对改善服务质量、研发新产品或服务等具有非常重要的意义。如您拒绝提供，您将无法正常使用相关附加功能或无法达到该服务的最佳效果，但不会影响使用该服务的基本功能。
                </p>
                <p style="text-indent:2em;">
                    （二）我们仅会出于以下目的和情形，收集和使用您的个人信息：
                </p>
                <p style="text-indent:2em;">
                    <strong>1、注册</strong>
                </p>
                <p style="text-indent:2em;">
                    当您注册为服务平台会员时，您需要向我们提供以下信息：手机号码和密码。我们将通过发送短信验证码来验证您的手机号码是否有效。在广汽三菱车主创建账号、将账号与您的车辆关联的过程中，我们还将会把您与广汽三菱签署的购车合同中所提供的身份证号、户籍地情况、性别、住址与您的账号进行关联。在后续使用过程中如果您主动填写或补充您的个人信息，我们将可以根据您的需要，为您呈现更多个性化的服务。这些信息包括您的头像、昵称、真实姓名、性别、生日、常驻地、个新签名、感兴趣的圈层/内容/车系、合适的经销商等。如您未填写或后续删除、变更此类信息的，可能会影响（包括无法获取到）我们基于您的信息提供的内容、广告等，但不会影响注册及会员功能的正常使用。
                </p>
                <p style="text-indent:2em;">
                    <strong>2、车辆绑定</strong>
                </p>
                <p style="text-indent:2em;">
                    注册完成后，您可以进行车辆绑定，以便于广汽三菱为您更好地提供服务。绑定车辆需要您提供您的<strong>姓名、手机、身份证号、行驶证号、车架号、发动机号</strong>。如果您不授权我们使用这些信息，我们无法在您与您的车辆之间建立关联，但不影响其他功能的使用。
                </p>
                <p style="text-indent:2em;">
                    <strong>3、车联网实名认证</strong>
                </p>
                <p style="text-indent:2em;">
                    根据国家法规，车联网相关功能需要您进行实名认证才能使用，实名认证信息包括您的<strong>姓名、电话、身份证号码、身份证正反面照</strong>或通信运营商要求的<strong>其他身份证明</strong>。实名认证信息会通过移动运营商传给国家的实名认证平台进行身份审核。收集此类信息符合相关法律法规的网络实名制要求。我们会在特定功能开启前提示您进行个人信息的授权，请您关注并留意。如您不想接受此类信息，您可以拒绝，也可以随时关闭或退订。
                </p>
                <p style="text-indent:2em;">
                    <strong>4、新能源汽车远程监控数据上传</strong>
                </p>
                <p style="text-indent:2em;">
                    为了保障您的新能源车辆安全，根据法律法规要求，对车辆静态信息（VIN、发动机号/电机号、车牌号等）、车辆动态数据（车辆状态数据、驱动电机数据、动力电池数据、<strong>车辆位置信息等</strong>）进行监控或获取，并将其接入新能源汽车远程监控数据政府监测平台。
                </p>
                <p style="text-indent:2em;">
                    <strong>5、下单</strong>
                </p>
                <p style="text-indent:2em;">
                    当您在服务平台订购具体商品及/或服务时，服务平台会生成您购买该商品及/或服务的订单。在下单过程中，至少需要填写您的收货人姓名、收货地址、收货人联系电话等必要联系信息，同时该订单中会载明订单编号、物流公司及订单号、您所购买的商品或服务备注信息、您应支付的货款金额及支付方式。
                </p>
                <p style="text-indent:2em;">
                    <strong>6、支付功能</strong>
                </p>
                <p style="text-indent:2em;">
                    在您下单后，您可以选择服务平台合作的第三方支付机构（包括微信支付、支付宝及银联、网联等支付通道，以下称“支付机构”）所提供的支付服务。我们需要将您的订单号与订单信息描述与这些支付机构共享以实现其确认您的支付指令并完成支付。
                </p>
                <p style="text-indent:2em;">
                    <strong>7、客服与售后功能</strong>
                </p>
                <p style="text-indent:2em;">
                    为了能够解决您的问题以及提供更优质的服务，我们的电话客服和售后功能可能会使用您的姓名、联系电话、车辆信息（包括车辆型号、车辆识别码）、<strong>位置信息</strong>、车牌号、车辆发动机号、账号信息、订单信息。您可以根据自身的驾驶情况决定是否接受此类服务。
                </p>
                <p style="text-indent:2em;">
                    <strong>8、与您联系</strong>
                </p>
                <p style="text-indent:2em;">
                    向您提供我们的产品发布/服务升级/软件升级；向您发送与您购买的产品/服务相关的信息以及相关条款/条件/政策的变更，这些信息对您与我们的沟通至关重要。如您不想接受此类信息，可以随时退订。
                </p>
                <p style="text-indent:2em;">
                    <strong>9、活动参与</strong>
                </p>
                <p style="text-indent:2em;">
                    当您参与我们的营销或推广活动时，为了方便与您联系，我们会收集并记录您的联系信息，例如报名账号、姓名、账号昵称、电话、性别、年龄、微信号、身份证号、参与时间、默认车辆（如有）、默认车辆绑定的经销店（如有）邮寄地址，如您拒绝提供上述信息，我们将无法向您跟进后续需求或向您寄送礼物（如有）。
                </p>
                <p style="text-indent:2em;">
                    <strong>10、向您提供商品或服务信息展示的附加功能</strong>
                </p>
                <p style="text-indent:2em;">
                    为向您提供更符合您和您车辆需求的信息，我们会收集您的姓名、手机号码、<strong>身份证照片</strong>、行驶证照片、车辆识别代号（VIN）、发动机号、车辆信息、设备信息。
                </p>
                <p style="text-indent:2em;">
                    <strong>11、向您提供与您和您的车辆相关的服务</strong>
                </p>
                <p style="text-indent:2em;">
                    我们会在以下场景中收集相应的信息，如果您不提供，则无法使用相应的项目及功能，但不影响本服务平台的基本功能。
                </p>
                <p style="text-indent:2em;">
                    </p><table border="1" style="border:solid black 1px;">
                        <tbody>
                            <tr>
                                <td>
                                    <p>
                                        <strong>客户服务环节</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <strong>场景</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <strong>涉及的服务平台</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <strong>收集的信息</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <strong>目的</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="3">
                                    <p>
                                        <strong>购车</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        看车选车
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱官方网站、广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、性别、电话、意向门店、意向车系、位置
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        浏览附近的经销商并留资咨询车系价格
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        预约试驾
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱官方网站、广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、性别、手机号码、位置信息、意向门店、意向车系、位置
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        获取用户信息，浏览附近的经销商，由用户本人进行预约试驾
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        在线订车
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱官方网站、广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、性别、手机号码、位置信息、意向门店、意向车系、车型、位置
                                    </p>
                                    <p>
                                        企业购车需提供：企业名称、统一社会信用代码、联系人手机号码、意向门店、意向车系、车型、位置
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        查看车辆价格、经销商服务、车辆选配、订金服务，进行线上订车/预约支付
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="5">
                                    <p>
                                        <strong>用车</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        车联网
                                    </p>
                                    <p>
                                        服务
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、菱行App
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、身份证/护照/通行证、手机号码、行驶证照片、机动车登记证、车辆识别代号（VIN）、车牌号、发动机号、车辆数据：车辆位置、车况信息（车速、档位、发动机状态、刹车状态、车辆故障及报警信号）、位置信息、紧急联系人电话
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        提供车联网服务，包括车辆定位、行驶数据，查找并提供适合您的停车地点，按照您的位置信息按距离排列经销商、加油站、充电桩，提供车联网车辆充电服务，提供车联网车辆投屏服务等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        救援出险
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、身份证/护照照片、手机号码、位置信息、行驶证照片，车辆识别代号（VIN）、车牌号、发动机号、车辆位置
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        车辆信息核对，根据地理位置进行道路救援等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        绑定车辆
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        车辆识别代号（VIN）、车辆发动机号、购车手机号码、购车身份证号/组织机构代码
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        提供广三车主服务（如：保养预约）等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        续保报价
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、手机号、邮箱地址、证件类型、证件号码、车牌号、品牌型号、车辆识别代码（VIN）、车辆发动机号、注册日期,行驶里程、购车日期、延保类型
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        提供续保报价等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        违章查询
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、手机号、车牌号、车辆识别代码（VIN）和车辆发动机号
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用于车辆及用户校验，提供违章查询服务
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="6">
                                    <p>
                                        <strong>养车</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        维修保养预约
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、手机号码、位置信息、车牌号、车型
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        向您推荐最近经销商，并实现车辆的维修保养预约等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        上门取送车
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、手机号码、取/送地址、车牌号
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        实现上门取送车等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        保养维修
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、手机号码、车型、车牌号、车辆识别代码（VIN）
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        提供适合您的保养维修套餐等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        钣喷报价
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、手机号码、车型、车牌号
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        提供报价等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        车友会
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱APP、广汽三菱MSPACE小程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、身份证/护照照片、手机号码、行驶证照片，车辆识别代号（VIN）、车牌号、发动机号、机动车登记证照片
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        确认为广汽三菱车主，为车主提供车友会活动资讯等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        商城
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        广汽三菱官方网站、广汽三菱APP
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        姓名、身份证/护照照片、手机号码、位置信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        供客户选购商品，并完成派送等
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        <strong>共通</strong>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        共通服务
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <br data-filtered="filtered">
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        诸如资讯、活动等，收集设备信息，位置信息、用户及车型信息等
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用于标识、校验用户及车辆，提供更为精准化的服务等
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                <p></p>
                <p style="text-indent:2em;">
                    <strong>12、对您设备的访问权限</strong><br data-filtered="filtered">
                上述功能可能需要在您的设备中向我们开启您的以下访问权限，以实现这些功能所涉及的信息的收集和使用。
                </p>
                <p style="text-indent:2em;">
                    (1)存储权限：为了保证您安装的应用稳定运行以及文章分享、课程分享到其他平台、资讯内保存图片的功能，我们需要您授权存储使用权限。
                </p>
                <p style="text-indent:2em;">
                    (2) 摄像头/麦克风/相机/相册权限：为了保证您能够正常使用本客户端的扫一扫、拍摄您的证件信息和爱车照片用于验证功能、车主安全认证的功能、发布动态、上传图片、发布短视频、设置头像等，便于分享更好的视频内容在社区资讯上，我们会请求您授权摄像头、相册、麦克风权限功能。您如果拒绝提供此类授权，将无法使用此功能，但不会影响其他服务的使用。
                </p>
                <p style="text-indent:2em;">
                    (3) 位置权限：为了保证您能正常使用我们的车辆定位、高德地图（救援出险，经销商地图模式，附近加油站等）的功能，我们需要您授权我们获取您的位置信息使用权限。
                </p>
                <p style="text-indent:2em;">
                    （4）软件权限：由于分享、地图、第三方登录等功能使用时会调用设备已安装的软件，因此我们会请求获取用户设备已安装的软件列表或运行中的进程信息，您如果拒绝提供此类授权，将无法使用此功能，但不会影响其他服务的使用。
                </p>
                <p style="text-indent:2em;">
                    (5) 手机状态权限：为了用于读取设备硬件信息，判断账户与设备的关联关系，通过技术与风控规则提高登录与交易安全性。我们会在您授权后获取您的读取手机状态权限。
                </p>
                <p style="text-indent:2em;">
                    您可以在您的设备中通过“设置”（如：iOS系统中的“设置-隐私”及Android系统中的“设置-应用权限”）逐项查看您上述权限的开启状态，并可以决定将这些权限随时开启或关闭。请您注意，您自主选择开启这些权限即代表您明确同意授权我们可以收集和使用这些个人信息来实现上述功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的收集和使用。
                </p>
                <p style="text-indent:2em;">
                    <strong>13、事先征得授权同意的例外</strong>
                </p>
                <p style="text-indent:2em;">
                    请您理解，以下情形中，我们收集、使用您的相关个人信息无需征得您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
                </p>
                <p style="text-indent:2em;">
                    （1）与我们履行法律法规规定的义务相关的；
                </p>
                <p style="text-indent:2em;">
                    （2）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
                </p>
                <p style="text-indent:2em;">
                    （3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需
                </p>
                <p style="text-indent:2em;">
                    （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
                </p>
                <p style="text-indent:2em;">
                    （5）在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
                </p>
                <p style="text-indent:2em;">
                    （6）法律法规规定的其他情形。
                </p>
                <p style="text-indent:2em;">
                    请您充分知晓，若我们采用技术手段对个人信息进行处理，使得个人信息主体无法被识别且信息经处理后不能被复原，则此类处理后数据的使用无需另行向您通知并征得您的同意。
                </p>
                <p style="text-indent:2em;">
                    <strong>14、其他紧急或必要情形</strong>
                </p>
                <p style="text-indent:2em;">
                    在以下几种情况发生时，我们可能会主动获取车辆位置信息：
                </p>
                <p style="text-indent:2em;">
                    1.注册车辆的产权所有人或注册使用者要求我们提供车辆位置服务；
                </p>
                <p style="text-indent:2em;">
                    2.注册车辆的安全气囊爆开；
                </p>
                <p style="text-indent:2em;">
                    3.注册车辆发生碰撞，并向广汽三菱客服中心发出了自动求助信号；
                </p>
                <p style="text-indent:2em;">
                    4.应法律要求（包括已生效的可能涉及刑事调查的法院命令）而向相关的国家机构提供其指定的车辆位置信息。
                </p>
                <p style="text-indent:2em;">
                    <strong>15、我们从第三方获得您个人信息的情形</strong>
                </p>
                <p style="text-indent:2em;">
                    为方便您使用相关产品和/或服务，提升您的用户体验，我们可能会从第三方或关联公司处收集您的个人信息，我们将依据与第三方或关联公司的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的个人信息。同时，我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
                </p>
                <p style="text-indent:2em;">
                    1.在您购买、租赁、使用相关产品和/或服务的车辆时，我们将通过销售车辆的经销商、租赁商渠道收集相关信息；
                </p>
                <p style="text-indent:2em;">
                    2.为了提高广汽三菱服务质量、满足用户需求，我们通过您与广汽三菱或广汽三菱授权的任何第三方之间的电话或电子邮件沟通收集信息；
                </p>
                <p style="text-indent:2em;">
                    3.通过第三方服务提供商或其它业务合作方（如无线服务提供商）收集信息；
                </p>
                <p style="text-indent:2em;">
                    4.根据车型不同，我们或者第三方应用程序作为采集主体，通过SDK、APK或其他类似的应用程序收集您的相关信息：
                </p>
                <p style="text-indent:2em;">
                    </p><table border="1" style="border:solid black 1px;">
                        <tbody>
                            <tr>
                                <td>
                                    <p>
                                        第三方应用程序
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        涉及平台
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        收集目的
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        收集的信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        收集方式
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        酷狗音乐
                                    </p>
                                </td>
                                <td rowspan="21">
                                    <p>
                                        DA系统
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证、内容投放、个性化推荐、产品改善、会员开通
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、DAID、App版本信息、网络状态、屏幕尺寸、APP操作记录、会员订单
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        酷我音乐
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证、内容投放、个性化推荐、产品改善、会员开通
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、DAID、App版本信息、网络状态、屏幕尺寸、APP操作记录、会员订单
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        悦听
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证、内容投放、个性化推荐、产品改善、会员开通
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、DAID、App版本信息、网络状态、屏幕尺寸、APP操作记录、会员订单
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        喜马拉雅
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证、内容投放、个性化推荐、产品改善、会员开通
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、DAID、App版本信息、网络状态、屏幕尺寸、APP操作记录、会员订单
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        优酷视频
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证、内容投放、个性化推荐、产品改善、会员开通
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、DAID、App版本信息、网络状态、屏幕尺寸、APP操作记录、会员订单
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        生活服务
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证，基于当前位置推荐内容
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、位置信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        KIKA输入法
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        构建热词
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        脱敏后的通讯录
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        墨迹天气
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用于查询当前位置天气信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        车辆位置信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        桌面天气
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用于查询当前位置天气信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        车辆位置信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        百度地图
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证、车辆定位、路线规划、产品改善
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、DAID、车辆位置、历史行程、操作记录
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        高德地图
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证、内容投放、个性化路线计算、产品改善、用户数据同步
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID、DAUID、App版本信息、网络状态、屏幕尺寸、APP操作记录、位置信息（若授权）、车牌号码（若用户填写）
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        语音
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证，内容投放，个性推荐，产品改善
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        设备ID，APP版本信息，网络状态，APP操作记录
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        应用商城
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证，内容投放，个性推荐，产品改善
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID,DAID,APP版本信息，网络状态，APP操作记录
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        个人中心
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证，产品改善
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID,DAID,APP版本信息，网络状态，APP操作记录
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        AI手册
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        产品改善
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APP操作记录,APP版本，网络状态
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        消息中心
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证，内容投放，个性推荐产品改善
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID，DAID,APP操作记录
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        聚听娱乐
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        身份验证，内容投放，个性推荐，产品改善
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用户ID，DAID,APP版本信息，网络状态，APP操作记录，会员订单
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        APK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        OTA升级
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用于精准推送OTA升级包
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        设备信息：软件版本、车辆VIN码
                                    </p>
                                    <p>
                                        涉及获取权限：网络连接，存取权限
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        SDK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        QQ互联、腾讯分享、新浪微博
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        分享服务：为向您提供便捷的分享服务，分享内容到微信/QQ/微博
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        设备Mac地址、唯一设备识别码（IMEI/android
                  ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息）、设备信息、设备厂商、设备名称、操作系统版本。涉及获取权限：网络状态权限、外置存储存取权限、手机品牌、型号、IMEI、网络连接
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        SDK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        高德
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        定位服务：地图导航和其他位置服务
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        获取设备位置权限并收集设备所在位置相关信息、设备识别好、联网相关信息
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        SDK
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        com.umeng
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        用于提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        设备信息（IMEI/MAC地址/Android
                  ID/IDFA/OpenUDID/GUID/SIM卡IMSI信息/地理位置/设备序列号/软件安装列表等）
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        SDK、
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        极光推送、小米推送、VIVOPUSH
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <br data-filtered="filtered">
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        实现App消息和通知能力
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        收集信息类型：为及时准确提供App相关信息，需获取“读写SD卡权限”、“网络访问权限”、“设备信息权限”
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        SDK
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                <p></p>
                <p style="text-indent:2em;">
                    <strong>16.保障信息安全</strong>
                </p>
                <p style="text-indent:2em;">
                    为提高您使用相关产品和/或服务时账号与系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能需要收集您的一些信息，以防产生任何危害用户、广汽三菱、社会的行为，包括您的如下个人信息：个人常用设备信息(Android
                ID / IDFA / OPENUDID / OAID / IMSI信息)、IP、设备型号、设备制造商、硬件序列号、SSID、任务信息、操作系统类型及型号、屏幕分辨率、电信运营商、软件安装列表、软件版本号、系统语言、SDK卡容量，以及个人敏感信息：交易信息、实名认证信息。我们会根据上述信息来综合判断您账号、账户及交易风险、进行身份验证、客户服务、检测及防范安全事件、诈骗监测、存档和备份用途，并依法采取必要的记录、审计、分析、处置措施，一旦我们检测出存在或疑似存在账号安全风险时，我们会使用相关信息进行安全验证与风险排除，确保我们向您提供的产品和服务的安全性，以用来保障您的权益不受侵害。同时，当发生账号或系统安全问题时，我们会收集这些信息来优化我们的产品和服务。
                </p>
                <p style="text-indent:2em;">
                    此外，为确保您设备操作环境的安全以及提供我们的产品与/或服务所必需，防止恶意程序和反作弊，我们会在您同意本政策后获取您设备上已安装或正在运行的必要的应用/软件列表信息。请您知悉，单独的应用/软件列表信息无法识别您的特定身份。
                </p>
                <p style="text-indent:2em;">
                    <strong>三、我们如何保护您的个人信息</strong>
                </p>
                <p style="text-indent:2em;">
                    <strong>（一）数据安全技术措施</strong>
                </p>
                <p style="text-indent:2em;">
                    1、我们非常重视您的个人信息安全，我们会采用符合法律法规和业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问、公开披露、使用、修改、避免数据的损坏或丢失。，例如，我们会确保只有授权人员和必要人员才可访问个人信息，我们有专门的安全人员来负责数据的安全防护和评估，我们会举办安全和隐私保护培训课程，加强员工对保护个人信息重要性的认识，会采取加密技术确保数据的保密性，采购有效的安全防护方案作为加密保护措施。
                </p>
                <p style="text-indent:2em;">
                    2、我们会采取一切合理可行的措施，确保不会收集与实现特定目的无关的个人信息。
                </p>
                <p style="text-indent:2em;">
                    3、我们将会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。我们确定存留期的标准包括：完成该业务目的需要留存个人信息的必要时间，包括提供产品和服务，保证产品和服务的安全，应对可能的用户查询或投诉，问题定位，用户是否同意更长的留存期间等。
                </p>
                <p style="text-indent:2em;">
                    4、互联网并非绝对安全的环境，而且电子邮件、即时通讯、其他交流方式可能并未加密，我们强烈建议您不要通过此类方式发送您的个人信息。请使用复杂密码，协助我们保证您的账号安全。
                </p>
                <p style="text-indent:2em;">
                    5、在我们处理您的个人信息的过程中，鉴于现有技术条件以及网络服务所具有的特殊性质，仍可能因为受到计算机病毒、木马、恶意程序、黑客攻击的破坏，或网络漏洞、异常波动，而导致您的信息受到损害，我们会尽一切最大努力避免或挽回相应的损失，并依法向您告知相关情况。
                </p>
                <p style="text-indent:2em;">
                    <strong>（二）&nbsp;&nbsp;信息的存储、安全保障能力</strong>
                </p>
                <p style="text-indent:2em;">
                    1、存储位置：中华人民共和国境内收集和产生的信息，我们将存放于中国境内如果发生数据的跨境传输，我们会请求您对跨境转移个人信息的同意，具体政策请参见第八条“您的个人信息如何跨境转移”的规定。
                </p>
                <p style="text-indent:2em;">
                    2.&nbsp;存储期限：我们将在您使用服务平台期间及达成本政策所述目的的合理必要期间内为您保存您的个人信息，除非法律有强制的留存要求。在超出存储期间后，我们会根据适用法律的要求删除您的个人信息，或将其匿名化处理。
                </p>
                <p style="text-indent:2em;">
                    当您存在下述情况时，我们将会依法对您的信息进行永久保存：
                </p>
                <p style="text-indent:2em;">
                    （1）为配合人民检察院、公安机关、国家安全机关侦查用户使用广汽三菱服务过程中产生的犯罪行为、更好保护其他用户生命财产安全，当用户自主删除个人信息或注销账户，我们将在刑法规定的犯罪追诉时效期间内，加密隔离存档用户个人信息。
                </p>
                <p style="text-indent:2em;">
                    （2）如您使用广汽三菱服务的过程中，存在严重违反法律法规、平台协议、平台规则等情形，您的违法、违约记录及相应的平台信用记录，将被永久保存。
                </p>
                <p style="text-indent:2em;">
                    <strong>（三）安全事件处置</strong>
                </p>
                <p style="text-indent:2em;">
                    1.&nbsp;为应对个人信息泄漏、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应小组，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
                </p>
                <p style="text-indent:2em;">
                    2.&nbsp;在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
                </p>
                <p style="text-indent:2em;">
                    <strong>（四）停止运营</strong>
                </p>
                <p style="text-indent:2em;">
                    1.&nbsp;如广汽三菱停止运营，我们将至少提前30日在相应平台以及相关媒介发布公告，并及时停止收集个人信息。停止运营后，我们将停止对个人信息的商业化使用，并在满足法律法规规定的最短保存期后，对收集的个人信息进行匿名化处理。
                </p>
                <p style="text-indent:2em;">
                    2.&nbsp;我们在基于前项规定而让服务提供方使用个人信息时，应努力防止被服务提供方发生个人信息的遗失、破坏、篡改、遗漏等问题，同时要求服务提供方遵守与个人信息保护有关的法律、法规以及其它政策。
                </p>
                <p style="text-indent:2em;">
                    <br data-filtered="filtered">
                </p>
                <p style="text-indent:2em;">
                    <strong>四、您的权利（查询、编辑、更正、删除、撤回同意、注销）</strong>
                </p>
                <p style="text-indent:2em;">
                    您应确保提交的所有个人信息都准确无误。我们将尽力维护您信息的准确和完整，并根据您的指示，及时更新这些数据。按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
                </p>
                <p style="text-indent:2em;">
                    <strong>1、查询、编辑您的个人信息</strong>
                </p>
                <p style="text-indent:2em;">
                    您可以通过您所访问平台的个人中心或个人界面，查询、编辑您的个人信息、车辆基本信息、车牌信息、实名认证状态信息、账号信息。如相关界面未显示您需要的信息，您可以随时通过本政策下方的联系方式进行咨询和获取。在一般情况下，我们会在十五天内回复您的访问请求。
                </p>
                <p style="text-indent:2em;">
                    <strong>2、更正您的个人信息</strong>
                </p>
                <p style="text-indent:2em;">
                    当您需要更新您的个人信息、车辆信息、车牌信息、实名认证信息、账号信息时，您有权做出更新或更正。您可以在相应平台自行进行更正或修改个人信息，或通过本政策下方的联系方式与我们联系，我们会在15天内回复您的更正请求。
                </p>
                <p style="text-indent:2em;">
                    <strong>3、删除您的个人信息。</strong>
                </p>
                <p style="text-indent:2em;">
                    如您希望删除个人信息，根据不同平台的界面，您可以通过线上解除绑定、注销账号或自行删除信息，（除非发生法律法规规定或本政策提及的需要延长或永久保存信息的情形时）我们将对您的个人信息进行删除或匿名化处理，不再对您的个人信息进行处理；
                </p>
                <p style="text-indent:2em;">
                    当您自主删除个人信息或注销账户，我们将验证您的个人身份，当您的申请通过后，我们将在15个工作日内完成对您账户个人信息进行直接删除或匿名化处理；
                </p>
                <p style="text-indent:2em;">
                    单独存储在车内的信息，您可以直接操作删除；如您需要删除我们按本政策处理的，或第三方处理的其他个人信息，可通过本政策下方的联系方式与我们取得联系，我们将按照流程确认您的身份、确认相应个人信息的状态进行处理。
                </p>
                <p style="text-indent:2em;">
                    <strong>4、改变您授权同意的范围</strong>
                </p>
                <p style="text-indent:2em;">
                    您可以通过系统的设置功能，关闭相应设备权限（包括位置、通讯录、照片、相机、通知等）、数据权限，撤回平台获取您个人信息的授权；
                </p>
                <p style="text-indent:2em;">
                    您还可以通过退出账号、解除绑定、删除信息等方式撤回部分授权；您随时可注销已注册的账户，如果您需要注销您的帐号，请在APP中【我的-设置-账号与安全-帐号注销】中提交申请。在注销账户之后，我们将停止为您提供产品或服务，并依据适用法律的要求，删除您的个人信息或对其进行匿名化处理。
                </p>
                <p style="text-indent:2em;">
                    当您撤回同意或授权后，可能导致我们无法为您继续提供撤回授权部分对应的服务，也不再收集和处理您的个人信息。但您撤回同意或授权，不影响撤回前基于您的同意开展的个人信息处理；
                </p>
                <p style="text-indent:2em;">
                    <strong>5、注销账户</strong>
                </p>
                <p style="text-indent:2em;">
                    您可以通过相关平台个人界面、文末的客服联系方式或者相关平台的在线客服进行账号注销申请。除法律法规另有规定外，注销账号之后，我们将停止为您提供产品或服务，并及时删除您的个人信息或进行匿名化处理。
                </p>
                <p style="text-indent:2em;">
                    <strong>6、响应您的上述请求。</strong>
                </p>
                <p style="text-indent:2em;">
                    您可以通过以下途径向我们提交上述权利请求：联系购买时的授权经销商、注册广汽三菱APP、广汽三菱MSPACE小程序及拨打客服电话。为保障安全，您可能需要以书面方式提交上述请求，同时，我们可能会先要求您验证自己的身份，然后再处理您的请求 。
                </p>
                <p style="text-indent:2em;">
                    对于您向我们提出的上述请求，我们将在十五天内做出答复。考虑到要求的复杂性和数量，在必要的时候，这一期限可能再延长一个月。如您不满意，还可以通过以下途径投诉：
                </p>
                <p style="text-indent:2em;">
                    联系购买车辆时的广汽三菱授权经销商，客服电话：400-977-3030。
                </p>
                <p style="text-indent:2em;">
                    对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、会给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
                </p>
                <p style="text-indent:2em;">
                    出现法律法规规定的以下例外情形时，我们将无法响应您的请求。
                </p>
                <p style="text-indent:2em;">
                    1.与我们履行法律法规规定的义务相关的；
                </p>
                <p style="text-indent:2em;">
                    2.与国家安全、国防安全直接相关的；
                </p>
                <p style="text-indent:2em;">
                    3.与公共安全、公共卫生、重大公共利益直接相关的；
                </p>
                <p style="text-indent:2em;">
                    4.与刑事犯罪侦查、起诉、审判和执行判决等直接相关的；
                </p>
                <p style="text-indent:2em;">
                    5.有充分证据表明个人信息主体存在主观恶意或者滥用权利的；
                </p>
                <p style="text-indent:2em;">
                    6.出于维护个人信息主体或者其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
                </p>
                <p style="text-indent:2em;">
                    7.响应您的请求将导致您或者其他个人、组织的合法权益受到严重损害的；
                </p>
                <p style="text-indent:2em;">
                    8．涉及商业秘密的。
                </p>
                <p style="text-indent:2em;">
                    <strong>五、我们如何共享、转让、公开披露您的个人信息</strong>
                </p>
                <p style="text-indent:2em;">
                    <strong>1、共享</strong>
                </p>
                <p style="text-indent:2em;">
                    未经您的同意，我们不会与广汽三菱以外的任何公司、组织和个人分享您的个人信息。但以下情形除外：&nbsp;
                </p>
                <p style="text-indent:2em;">
                    1.1根据法律法规规定、按行政、司法机关依法提出的要求，提供您的个人信息。&nbsp;
                </p>
                <p style="text-indent:2em;">
                    1.2为更好的提供服务或者满足产品和服务的必须，我们可能会将您的个人信息与我们的关联方共享。但我们只共享必要的个人信息，且受本隐私协议中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
                </p>
                <p style="text-indent:2em;">
                    1.3.为促进合作、推出优质及更新的产品及服务，我们可能会向第三方合作伙伴共享您的账号信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：
                </p>
                <p style="text-indent:2em;">
                    (1) 我们聘请的第三方服务提供者（如供应商、服务外包商等）。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括但不限于：购车贷款、车辆保险、车载信息、路边救援、电话回访、召回通知、发送信函、电子邮件或短信、
                清理客户信息、分析数据、提供市场营销帮助、客户满意度调查、数据调研、接受客户咨询以及处理客户请求、技术服务、交易积分兑换及查询、加油服务、路书服务、实名认证/解除实名认证以及购买服务套餐、购买车机主题皮肤及使用、智行无忧保、车辆价值估算、敏感词过滤/获取短信验证码、实现三方合作平台账号登录和绑定、获取用户/车辆位置、获取车辆违章数据、电话一键登录、违章查询服务、DA故障诊断、支付定金等等。
                </p>
                <p style="text-indent:2em;">
                    (2)广汽三菱授权销售店。我们必须将您的订单信息与服务有关的必要信息与广汽三菱授权销售店共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。
                </p>
                <p style="text-indent:2em;">
                    (3)委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴(“委托方”)共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
                </p>
                <p style="text-indent:2em;">
                    (4)对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私协议以及其他任何相关的保密和安全措施来处理个人信息。为了保障数据在第三方安全可控，我们使用了云服务，在云端提供安全可靠的数据使用和存储环境，确保用户数据的安全性。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
                </p>
                <p style="text-indent:2em;">
                    (5)为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护广汽三菱、您或其他广汽三菱客户的权利及其财产或安全（如为防止欺诈等违法活动和减少信用风险)，我们可能会与其他公司和组织交换信息，但我们不会为获利而违反本隐私协议中所作的承诺，以出售、出租、共享或以其它方式违法违约披露个人信息。
                </p>
                <p style="text-indent:2em;">
                    <strong>2、转让</strong>
                </p>
                <p style="text-indent:2em;">
                    未经您的同意，我们不会将您的个人信息转让给任何公司、组织和个人。但以下情况除外：
                </p>
                <p style="text-indent:2em;">
                    1）获得您的同意后，我们会向其他方转让您的个人信息；
                </p>
                <p style="text-indent:2em;">
                    2）在涉及合并、收购、资产转让或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
                </p>
                <p style="text-indent:2em;">
                    <strong>3、公开披露&nbsp;</strong>
                </p>
                <p style="text-indent:2em;">
                    我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
                </p>
                <p style="text-indent:2em;">
                    （1）根据您的需求，在您明确同意的披露方式下，或基于您的主动选择，披露您所指定的个人信息；
                </p>
                <p style="text-indent:2em;">
                    （2）根据法律、法规的要求、强制性的行政执法或司法机关要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于相关单位因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
                </p>
                <p style="text-indent:2em;">
                    <strong>4、提供、转移、公开披露个人信息时事先征得授权同意的例外</strong>
                </p>
                <p style="text-indent:2em;">
                    您清楚并知悉，在下列情况下，我们收集、使用个人信息无需征得您的授权同意：
                </p>
                <p style="text-indent:2em;">
                    1.与我们履行法定职责或法律法规规定的义务相关的；
                </p>
                <p style="text-indent:2em;">
                    2.为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
                </p>
                <p style="text-indent:2em;">
                    3.为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
                </p>
                <p style="text-indent:2em;">
                    4.为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
                </p>
                <p style="text-indent:2em;">
                    5.依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
                </p>
                <p style="text-indent:2em;">
                    6.其他法律法规规定的情形；
                </p>
                <p style="text-indent:2em;">
                    <strong>六、我们如何使用Cookie和同类技术</strong>
                </p>
                <p style="text-indent:2em;">
                    我们使用Cookie和其他技术，以确保最佳的用户体验。Cookie可帮助我们保护您的账号安全。<strong>继续使用我们的服务，即表示您同意出于本政策所述的目的，使用Cookie及相关技术。</strong>Cookie的用途主要有以下几点：
                </p>
                <p style="text-indent:2em;">
                    1、改善产品及服务：我们使用cookie、标签和脚本等同类终端收集和存储技术。这些技术用于为使您获得更轻松安全的访问体验，改善产品服 务及用户体验，或及时发现并防范安全风险，为您提供更好的服
                务。这些缓存信息主要包括用户个人配置信息、APP临时运行参数 信息。&nbsp;
                </p>
                <p style="text-indent:2em;">
                    2、日志文件：和大部分网站和移动应用一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网政策(IP)地址、互联网服务供应商(ISP)、操作系统、 日期/时间戳和/或点击流数据。 我们不会将自动收集的数据链接到我们收集的关于您的其他
                信息之中。
                </p>
                <p style="text-indent:2em;">
                    3、移动应用分析：在本平台应用中，我们使用移动分析软件，以更好地了解我们的移动软件在您的手机中的功能。此款软件可能记录以下信息，如您使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。我们不会将存储于分析软件内的信息链接到您在移动应用程序中提交的任何个人信息。
                </p>
                <p style="text-indent:2em;">
                    <br data-filtered="filtered">
                </p>
                <p style="text-indent:2em;">
                    使用SDK名称：友盟SDK
                </p>
                <p style="text-indent:2em;">
                    服务类型：数据统计分析
                </p>
                <p style="text-indent:2em;">
                    收集个人信息类型：设备信息（IMEI/MAC/Android
                </p>
                <p style="text-indent:2em;">
                    ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）
                </p>
                <p style="text-indent:2em;">
                    隐私权政策链接：<a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a>
                </p>
                <p style="text-indent:2em;">
                    使用SDK名称：openinstall
                SDK
                </p>
                <p style="text-indent:2em;">
                    服务类型：数据统计分析/用于数据统计作弊监控
                </p>
                <p style="text-indent:2em;">
                    收集个人信息类型：设备信息（SDK版本、应用程序版本、应用程序包名、IP地址（用于地理位置定位）、设备型号、终端制造产商、终端设备操作系统版本、Android
                ID、SerialNumber、设备序列号）,并使用粘贴板（剪切板）获取粘贴板信息辅助统计增加统计精度的功能。
                </p>
                <p style="text-indent:2em;">
                    隐私权政策链接：<a href="https://www.openinstall.io/privacy.html">https://www.openinstall.io/privacy.html</a>
                </p>
                <p style="text-indent:2em;">
                    <br data-filtered="filtered">
                </p>
                <p style="text-indent:2em;">
                    使用SDK名称：卓信ID SDK<br data-filtered="filtered">
                服务类型：数据统计分析<br data-filtered="filtered">
                收集信息类型：设备弱特征（不具备唯一性和稳定性的特征），用于生成卓信ID，作为设备标识信息
                </p>
                <p style="text-indent:2em;">
                    <strong>七、我们会如何处理未成年人的个人信息</strong>
                </p>
                <p style="text-indent:2em;">
                    1、我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品及服务前，应事先取得您家长或法定监护人的明确同意。
                </p>
                <p style="text-indent:2em;">
                    2.对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
                </p>
                <p style="text-indent:2em;">
                    3. 我们将根据国家相关法律法规的规定保护未成年人的个人信息。如果我们发现在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
                </p>
                <p style="text-indent:2em;">
                    4.如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过本隐私政策第十条“如何联系我们"所述联系方式联系我们，我们会设法尽快删除相关数据。
                </p>
                <p style="text-indent:2em;">
                    <strong>八、您的个人信息如何跨境转移</strong>
                </p>
                <p style="text-indent:2em;">
                    原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。目前本政策提及的产品和/或服务不存在向境外提供个人信息的场景。如将来涉及向境外传输个人信息，我们将明确向您告知个人信息出境的目的、接收方、安全保障措施等情况，并另行征得您的同意。
                </p>
                <p style="text-indent:2em;">
                    <strong>九、适用法律与争议解决</strong>
                </p>
                <p style="text-indent:2em;">
                    1、适用法律本政策的执行、解释及争议的解决均适用中华人民共和国法律，且不考虑任何冲突法。
                </p>
                <p style="text-indent:2em;">
                    2、争议解决您和广汽三菱及本政策内容或其执行发生任何争议的，双方应友好协商解决；
                如双方无法协商解决争议时，双方同意应将争议 提交至被告住所地人民法院裁决。
                </p>
                <p style="text-indent:2em;">
                    <strong>十、本政策如何更新</strong>
                </p>
                <p style="text-indent:2em;">
                    为更好地提供服务或根据法律法规的要求，未来我们可能需要不时对本政策进行变更或修改，该等修订构成本隐私政策的一部分，并具有溯及力。如果该变更或修改造成您在本政策下权利的实质变化，我们会向您提供显著的通知并获得您的同意。
                </p>
                <p style="text-indent:2em;">
                    为了更好保护您的个人信息，我们鼓励您时常查看服务平台内的页面以了解我们最新的隐私政策，并建议您完整阅读更新的隐私政策；若您不同意该等经修订后的隐私政策的任何内容，您即应选择停止使用本服务。但如果更新的内容需要收集您的地理位置、联系方式、身份信息等个人敏感信息，仍会再次以显著方式征求您的同意。
                </p>
                <p style="text-indent:2em;">
                    对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于短信、在浏览页面做特别提示、弹窗提示等方式，说明隐私政策的具体变更内容）。
                </p>
                <p style="text-indent:2em;">
                    本政策所指的重大变更包括但不限于：
                </p>
                <p style="text-indent:2em;">
                    1、 我们的服务模式发生重大变化。
                如处理个人信息的目的、 处理的个人信息类型、个人信息的使用方式等；
                </p>
                <p style="text-indent:2em;">
                    2、 我们在所有权结构、组织架构等方面发生重大变化。
                如业务调整、破产并购等引起的所有者变更等；
                </p>
                <p style="text-indent:2em;">
                    3、&nbsp;个人信息提供、转移或公开披露的主要对象发生变化；
                </p>
                <p style="text-indent:2em;">
                    4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
                </p>
                <p style="text-indent:2em;">
                    5、我们负责处理个人信息安全的责任部门、
                联络方式及投诉 渠道发生变化时；
                </p>
                <p style="text-indent:2em;">
                    6、个人信息安全影响评估报告表明存在高风险时。
                </p>
                <p style="text-indent:2em;">
                    我们还会将本政策的旧版本存档，供您查阅。
                </p>
                <p style="text-indent:2em;">
                    本政策在本平台发布或运营或提供使用期间有效，在本平台永久停止运营或永久停止提供使用时失效，失效后我们依然将根据国家法律法规要求保护您个人信息安全相关权益。
                </p>
                <p style="text-indent:2em;">
                    <strong>十一、如何联系我们</strong>
                </p>
                <p style="text-indent:2em;">
                    如果您有任何疑问、意见或建议，或有任何隐私投诉或问题，都可以通过以下方式与我们联系。
                </p>
                <p style="text-indent:2em;">
                    广汽三菱客服电话：400-977-3030
                </p>
                <p style="text-indent:2em;">
                    特别提示：广汽三菱提醒您，如您反馈的问题涉及您的个人信息，尤其是您的敏感信息，请您不要直接在电话或邮件中进行透露，我们将会在确保您个人信息安全的情况下与您进一步沟通。部分情况下，为保障您个人信息的安全，我们可能需要您提供书面请求，或以其他方式证明您的身份。我们将在收到您的反馈并验证您的身份后15工作日内答复您的相关请求。
                </p>
                <p style="text-align:right;text-indent:2em;">
                    版权所有©广汽三菱汽车有限公司2022。保留一切权利
                </p>
                <p style="text-align:right;text-indent:2em;">
                    最近更新日期：2022年10月12日
                </p>
                </div>

        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>





</style>