<!--  -->
<template>
    <div class="main">
        <div>
            <video-player 
            ref="videoM"
            class="video-player-box"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
        </div>
        <!-- <div style="width: 100%;">
            <img :src="require('../assets/img/Mstory02.png')" width="100%" alt="">
        </div> -->
        <div :style="bg1" class="bg01">
            <div style="width: 100%;"><img :src="require('../assets/img/Mstory02.png')" width="275.64px" alt=""></div>
            <div class="bg01">
                    
                    <el-carousel :autoplay="false" :loop="false" trigger="click" height="380px">
                      <el-carousel-item v-for="(item,index) in switchx" :key="index">
                        <div style="width: 100%;">
                            <img :src="item.img" width="100%" alt="">
                            <div style="font-size: 12px;width: 100%;display: flex;padding-top: 10px;">
                                <div style="width: 5%"></div>
                                <div style="width: 20%;height: 100px;box-sizing: border-box;padding: 5px;text-align: center;" v-for="(itm,idx) in item.desc">
                                    <div style="height: 100px;">{{itm}}</div>
                                    <div><img :src="require('../assets/img/'+item.title+(idx+1)+'.png')" width="223.59px" alt=""></div>
                                </div>
                            </div>
                            <div style="width: 15%"></div>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
            </div>
        </div>
        <div :style="bg5" class="bg01">
            <img :src="require('../assets/img/Mstorybg501.png')" width="1210px" alt="">
        </div>
        <div :style="bg6" class="bg01">
            <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: end;;">
                <div style="padding-bottom: 20px;font-size: 12px;color: #999797;">
                    三菱汽车50多年来一直挑战赛车运动。那是追求“奔跑的快乐”和“确切的安心”，挑战汽车的极限性能的场所。<br>
                    伴随着许多胜利和辉煌的成果，在那里获得的技术和知识被反馈到市售车的开发和制造中，并被传入你乘坐的汽车。
                </div>
            </div>
            <!-- <img :src="require('../assets/img/Mstorybg601.jpg')" width="1210px" alt=""> -->
        </div>
        <div :style="bg7" class="bg01">
            <div class="videox">
                <video-player 
                    ref="videox"
                    class="video-player-box"
                    :playsinline="true"
                    :options="playerOptionsX"
                ></video-player>
            </div>
            <img :src="require('../assets/img/Mstorybg701.png')" width="282.34px" alt="">
            <img :src="require('../assets/img/Mstorybg702.png')" width="1200.61px" alt="">
        </div>
        <div :style="bg8" class="bg01">
            <div>
                <img :src="require('../assets/img/Mstorybg801.png')" width="282.34px" alt="">
            </div>
            <!-- <img :src="require('../assets/img/Mstorybg702.png')" width="1376.61px" alt=""> -->
             <div  style="width: 90%;height: 100%;display: flex;justify-content: center;;">
                <div style="width: 50%;"><img :src="require('../assets/img/Mstorybg802.png')" width="100%" alt=""></div>
                <div style="width: 50%;font-size: 16px;text-align: left;">
                    <div style="padding: 40px 0px 20px 20px;font-size: 20px;font-weight: 800;">驾驭探索之趣</div>
                    <div></div>
                    <div style="padding-left: 20px;">
                        &emsp;&emsp;“驾驭”与三菱“Drive Your Ambition”口号高度切合，既体现
                        了百年三菱的技术和底蕴，又展现出产品在各种路况前行的信心；
                        “趣”代表消费者发现生活中的无限乐趣；整体寓意将为用户创
                        造无限乐趣的汽车生活，并鼓励所有人勇于探索、不断创新。
                        <br>
                        广汽三菱汽车销售有限公司核心用户有着渴望通过全新体验与发现，去获得内心充
                        实与乐趣，不断拓展人生宽度的一面，“驾驭探索之趣”的品牌
                        口号与用户富有活力、勇于探索的特性不谋而合，将对未来营销
                        走向产生指导性的影响。 
							
                    </div>
                </div>
             </div>
        </div>
        <div :style="bg9" class="bg01">
            <img :src="require('../assets/img/Mstorybg901.png')" width="282.34px" alt="">
            <img :src="require('../assets/img/Mstorybg902.png')" width="100%" alt="">
        </div>

        
        
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
//视频组件地址
//https://blog.csdn.net/weixin_38684316/article/details/88070737
export default {
//import引入的组件需要注入到对象中才能使用
// require("../assets/img/Mstorybg1.jpg")
components: {},
data() {
return {
    bg1:{
        backgroundImage: "url("+require("../assets/img/Mstorybg1.jpg")+")",
        width: '100%',
        height: '663px',
        backgroundSize:'100% 100%',
        
        
    },
    bg5:{
        backgroundImage: "url("+require("../assets/img/Mstorybg5.jpg")+")",
        width: '100%',
        height: '663px',
        backgroundSize:'100% 100%',
    },
    bg6:{
        backgroundImage: "url("+require("../assets/img/Mstorybg601.jpg")+")",
        width: '100%',
        height: '663px',
        backgroundSize:'100% 100%',
    },
    bg7:{
        backgroundImage: "url("+require("../assets/img/Mstorybg7.jpg")+")",
        width: '100%',
        height: '1488px',
        backgroundSize:'100% 100%',
    },
    bg8:{
        backgroundImage: "url("+require("../assets/img/Mstorybg8.jpg")+")",
        width: '100%',
        height: '594px',
        backgroundSize:'100% 100%',
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center'
        // justifyContent: 'center'
    },
    bg9:{
        backgroundImage: "url("+require("../assets/img/Mstorybg9.jpg")+")",
        width: '100%',
        height: '616px',
        backgroundSize:'100% 100%',
    },
    test:"Mstorybg201",
    switchx:[
        {
            img:require('../assets/img/Mstorybg2.jpg'),
            desc:[
                '1917年,三菱成功打造了一款搭载内燃机动力系统的四轮汽车“三菱A型”，它是全日本首部量产汽车。',
                '1935年生产的PX33车型是三菱首部四轮驱动（4WD）汽车   ,它的出现引起了世界车坛注目。',
                '1970年问世的Galant GTO以出色的乘坐舒适感打破了以往对单排座小车 “空间小” 的偏见。 它由Galant GTX-1改进而来，高性能版本的GTO-MR搭载了三菱汽车首款DOHC发动机（125PS），成为三菱历史上的人气车型之一。',
                '1973年三菱Lancer投产。 诞生伊始，Lancer就打上了“运动”的标签，从1974年非洲Safari拉力赛开始，Lancer赛车先后为三菱赢得WRC赛事34座冠军奖杯，成为车迷心中永恒的经典车型。'
            ],
            title:'Mstorybg20'
        },
        {
            img:require('../assets/img/Mstorybg3.jpg'),
            desc:[
                '2001年第一代Outlander诞生，它是名副其实的跨界车型先行者，兼具轿车的操控与SUV的空间性，它的出现引领了汽车界的跨界风潮。在全球收获了良好的市场反响。',
                '2006年,I-MiEV作为全球电动汽车的先行者与消费者见面，它获得了2009年日本汽车JAHFA奖，并在2011年德国电动车汽车市场销量名列前茅，其每百公里行驶成本仅为600cc排量汽油微型车的1/3。',
                '多年来一直参加WRC比赛并培养其电子控制四轮驱动技术的三菱，已将S-AWC纳入Lancer Evolution X，除了现有的ACD、AYC和体育ABS之外，还采用了ASC。 这些设备的集成控制改善了驾驶性能、转弯性能、稳定性能和车辆的行为忠实于驾驶员在任何路面上的操作。',
                '2012年广汽三菱首款车型劲炫ASX上市，它拥有电子控制四驱系统和6速运动模式，在提供优越通过性的同时还能兼顾公路操控性，迅速获得了中国消费者的认可。'
            ],
            title:'Mstorybg30'
        },
        {
            img:require('../assets/img/Mstorybg4.jpg'),
            desc:[
                '2013年广汽三菱的第二款SUV车型新帕杰罗·劲畅Pajero Sport投入生产，誉承达喀尔冠军的纯正血统，同时提供给中国消费者商务、家用、休闲、出行所需的自由空间。',
                '2016年广汽三菱的第三款SUV车型欧蓝德Outlander上市，以“2047”（20万4驱7座）的强大产品优势，刷新了中高级城市SUV价值标杆。',
                '2018年广汽三菱第四款SUV车型奕歌上市，凭借强大运动基因、领先驾控性能，它拥有了紧凑级SUV中超强的驾控表现。',
                '2019年广汽三菱新劲炫上市，采用三菱家族最新设计语言，外观犀利霸气；专为中国市场研制的内饰设计，时尚舒适，尽显“10万级合资SUV优选”实力。'
            ],
            title:'Mstorybg40'
        }
    ],
    selected:'',
    playerOptions: {
        // 这里可以配置播放器的选项，如自动播放、循环播放等
        autoplay: false,
        loop: false,
        width:"1347px",
        // 视频源
        sources: [
          {
            type: 'video/mp4', // 视频的类型
            src: require('../assets/img/Mitsubishi.mp4') // 视频的路径
          }
        ],
        poster:require('../assets/img/MitsubishiBig.jpg')
        // 其他需要的配置...
      },
      playerOptionsX: {
        // 这里可以配置播放器的选项，如自动播放、循环播放等
        autoplay: false,
        loop: false,
        width:"586px",
        // 视频源
        sources: [
          {
            type: 'video/mp4', // 视频的类型
            src: require('../assets/img/s-awc.mp4') // 视频的路径
          }
        ],
        poster:require('../assets/img/s-awc.png')
        // 其他需要的配置...
      }
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    show(e){
        this.selected = e
        // this.show_img = 'airtke_'+'e'
    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.selected = 'base'
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.bg01{
    padding-top: 56px;
    text-align: center;
}

.videox{
    position: absolute;
    z-index: 1001;
    width: 586px;
    height: 379px;
    top: 3393px;
    right: 112px;
    /* background-color: red; */
}

</style>