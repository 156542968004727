<!--  -->
<template>
    <div style="display: flex;width: 100%;height: 100%;background-color: black;;">
        
        <div><img :src="require('../assets/img/pc.jpg')" height="731px" width="819px" alt=""></div>
        <div style="width: 100%;height:100%;">
            <div style="width: 100%;text-align: right;"><img style="padding-right: 30px;" :src="require('../assets/img/logo-right.png')" ></div>
            <div style="height: 50px;"></div>
            <div style="text-align: center;"><img :src="require('../assets/img/title.png')" height="30px" width="402px" alt=""></div>
            <div style="width: 100%;height: 200px;display: flex;align-items: center;justify-content: center;">
                <div class="vin_input">

                    <div v-if="vin_check==0" style="width: 350px;font-size: 16px;" >
                        <div >查询您的平行进口车辆是否享受官方质保</div>
                        <div style="width: 360px;">
                            <el-input placeholder="请输入车架号" v-model="vin" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                        </el-input>
                        </div>
                    </div>
                    <div v-if="vin_check==1" style="width: 360px;" >
                        <div>很抱歉！您所购买的车辆不在质保范围内。</div>
                    </div>
                    <div v-if="vin_check==2" style="width: 360px;" >
                        <div style="padding-bottom: 5px;">恭喜！您所购的平行进口车辆享受官方质保。</div>
                        <div><img :src="require('../assets/img/car.png')" height="66px" width="246px" alt=""></div>
                        <div style="padding-top: 5px;">我们将提供五年十万公里的质保承诺，请安心选购以及使用车辆。</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    vin:"",
    vin_check:0  //0:输入，1：不是，2：是
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    search(){
        console.log("vin",this.vin)
        this.$axios
          .get("/api/DCMS/common/iclub/MspaceAction/getVinInfo.json?vin="+this.vin)
          .then((res) => {
            // console.log("res",JSON.parse(res.data))
            try {
                let s = res.data
                //生成JSON格式键名
                s = s.replace(new RegExp("result","g"),'"result"')
                s = JSON.parse(s)
                
                console.log("xxx",s)
                if(s.result=="true"){
                    this.vin_check = 2
                }else{
                    this.vin_check = 1
                }
                


            } catch (error) {
                console.log("error",error)
                this.$message('数据检索失败');
            }
          })
    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


/* .content {
  width: 79%;
  margin: 0 auto;
} */


/* .manual_div{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
} */

.manual-item{
    width: 287px;
}

.vin_input{
    font-size: 18px;
    color: #ebe7e7;
    width: 80%;
    /* height: 70px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    border: 2px solid #5a5b5e;
    
    box-sizing: border-box;
}

</style>