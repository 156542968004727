<!--  -->
<template>
    <div  >
        <!-- <div><img :src="require('../assets/img/aboutus.jpg')" width="1450px" alt=""></div> -->
        <div v-if="newsinfo.title" style="width: 70%;font-size: 20px;" class="content">
            <!-- <div class="title" >{{ $route.query.name }}</div> -->
             <!-- <div style=""></div> -->
             <div style="margin-top: 40px;font-weight: 800;">{{newsinfo.title}}</div>
             <div style="margin-top: 20px;font-size: 16px;color: #a8a9ac;">{{newsinfo.createtime}}&emsp;&emsp;&emsp;访问量：{{parseInt(newsinfo.watch)+1}}</div>
             <div style="margin-top: 40px;font-size: 16px;" v-html="newsinfo.note"></div>
             <div v-if="newsinfo.img" style="margin-top: 10px"><div v-if="newsinfo.img.length>1"><img :src="'./img/'+newsinfo.img[1]"  alt=""></div></div>
            <!-- <div style="width: 100%;height: 185px;;display: flex;margin-top: 30px;" v-for="(item,index) in news" :key="index">
                <div style="width: 30%;">
                    <img :src="'/img/'+item.img[0]" height="185px" width="353px" alt="">
                </div>
                <div style="width: 70%;height: 100%;;padding-left: 20px;">
                    <div>{{item.title}}</div>
                    <div style="padding-top: 22px;font-size: 16px;color: #848588;">{{item.descx}}</div>
                    <div style="height: 90px;"></div>
                    <div style="font-size: 14px;color: #848588;width: 100%;text-align: right;padding-right: 100px;box-sizing: border-box;">{{item.createtime}}</div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    newsinfo:false,

};
},
//监听属性 类似于data概念
computed: {
    count() {
    //   return this.$store.state.SelectNews;
    // this.newsinfo =  this.$store.state.SelectNews;
    // console.log("this.newsinfo",this.newsinfo)
    }
},
//监控data中的数据变化
watch: {
    // count(newValue, oldValue) {
    //   console.log('count 发生变化:', newValue);
    //   this.newsinfo = newValue[0]
    // }
},
//方法集合
methods: {

    
 
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    console.log('id:',this.$route.query.id)
    this.$store.commit({
        type:'filerNews',
        id:this.$route.query.id
    })
    this.newsinfo =  this.$store.state.SelectNews[0];
    console.log("this.newsinfo",this.newsinfo)
    if(this.newsinfo==undefined){
        this.$router.back()
    }

    //访问量加1
    this.$axios
            .get("/mp/GetWxNewsWatchAdd.action?id="+this.$route.query.id)
              .then((res) => {
        
                
                try {
                    if(res.status=='200'){
                        // this.$message('浏览量更新成功'); 
                    }
                } catch (error) {
                    console.log("error",error)
                    // this.$message('浏览量更新失败');
                }
              })

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


/* .content {
  width: 79%;
  margin: 0 auto;
} */

.cx{
    display: flex;
    height: 100px;
    /* justify-content: space-between; */
    align-items: start;
}

.cx_item{
    display: flex;
    align-items: start;
    margin-right: 40px;
}

.cx_list{
    height: 200px;
}

/* .manual_div{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
} */

.manual-item1{
    height: 100px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #091d3a;
    width: 287px;
    margin-bottom: 20px;
}




</style>