import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home.vue'
import manual from '../components/manual.vue'
import service from '../components/service.vue'
import vin from '../components/vin.vue'
import airtrek from '../components/airtrek.vue'
import outlander from '../components/outlander.vue'
import outlander2022 from '../components/outlander2022.vue'
import asx from '../components/asx.vue'
import ns from '../components/ns.vue'
import l200 from '../components/l200.vue'
import Mstory from '../components/Mstory.vue'
import ys from '../components/ys.vue'
import client from '../components/client.vue'
import server from '../components/server.vue'
import aboutus from '../components/aboutus.vue'
import news from '../components/news.vue'
import newsinfo from '../components/newsinfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/service',
    name: 'service',
    component: service
  },
  {
    path: '/manual',
    name: 'manual',
    component: manual
  },
  {
    path: '/vin',
    name: 'vin',
    component: vin
  },
  {
    path: '/airtrek',
    name: 'airtrek',
    component: airtrek
  },
  {
    path: '/outlander',
    name: 'outlander',
    component: outlander
  },
  {
    path: '/outlander2022',
    name: 'outlander2022',
    component: outlander2022
  },
  {
    path: '/asx',
    name: 'asx',
    component: asx
  },
  {
    path: '/ns',
    name: 'ns',
    component: ns
  },
  {
    path: '/l200',
    name: 'l200',
    component: l200
  },{
    path: '/Mstory',
    name: 'Mstory',
    component: Mstory
  },{
    path: '/ys',
    name: 'ys',
    component: ys
  },{
    path: '/client',
    name: 'client',
    component: client
  },{
    path: '/server',
    name: 'server',
    component: server
  },{
    path: '/aboutus',
    name: 'aboutus',
    component: aboutus
  },{
    path: '/news',
    name: 'news',
    component: news
  },{
    path: '/newsinfo',
    name: 'newsinfo',
    component: newsinfo
  },
]

const router = new VueRouter({
  routes
})

export default router
