<!--  -->
<template>
    <div style="background-color: #e4e9ee;">
        <div><img :src="require('../assets/img/kv.jpg')" height="472px" width="1450px" alt=""></div>
        <div style="width: 90%;font-size: 20px;" class="content">
            <div class="title" >服务理念</div>
            <div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="专.心介绍" name="first">
                        <div style="width: 100%;">
                            <img :src="require('../assets/img/server01.jpg')" width="1032px" alt="">
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="保修政策" name="second">
                        <div style="width: 100%;">
                            <div style="display: flex;font-size: 14px;">
                                <div style="text-align: center;" @click="selectedx='outlander'">
                                    <div><img :src="require('../assets/img/outlander.png')"  width="200px" alt=""></div>
                                    <div>欧蓝德</div>
                                    <div :style="selectedx=='outlander'?'width: 100%;border: 1px #091d3a solid;':''"></div>
                                </div>
                                <div style="text-align: center;" @click="selectedx='asx'">
                                    <div><img :src="require('../assets/img/asx.png')"  width="200px" alt=""></div>
                                    <div>新劲炫</div>
                                    <div :style="selectedx=='asx'?'width: 100%;border: 1px #091d3a solid;':''"></div>
                                </div>
                                <div style="text-align: center;" @click="selectedx='ns'">
                                    <div><img :src="require('../assets/img/eclipse.png')"  width="200px" alt=""></div>
                                    <div>奕歌</div>
                                    <div :style="selectedx=='ns'?'width: 100%;border: 1px #091d3a solid;':''"></div>
                                </div>
                                <div style="text-align: center;" @click="selectedx='pajero'">
                                    <div><img :src="require('../assets/img/2019pajero.png')"  width="200px" alt=""></div>
                                    <div>帕杰罗</div>
                                    <div :style="selectedx=='pajero'?'width: 100%;border: 1px #091d3a solid;':''"></div>
                                </div>
                                <div style="text-align: center;" @click="selectedx='ev'">
                                    <div><img :src="require('../assets/img/ev.png')"  width="200px" alt=""></div>
                                    <div>褀智EV</div>
                                    <div :style="selectedx=='EV'?'width: 100%;border: 1px #091d3a solid;':''"></div>
                                </div>
                                <div style="text-align: center;" @click="selectedx='phev'">
                                    <div><img :src="require('../assets/img/phev.png')"  width="200px" alt=""></div>
                                    <div>褀智PHEV</div>
                                    <div :style="selectedx=='PHEV'?'width: 100%;border: 1px #091d3a solid;':''"></div>
                                </div>

                            </div>
                        </div>
                    </el-tab-pane>
                  </el-tabs>
            </div>
            <div style="width: 100%;height: 300px;;">
                <div style="width: 100%;padding: 20px 10px;" v-if="activeName=='second'&&selectedx=='outlander'">
                    <img :src="require('../assets/img/server02.jpg')" width="1067px" alt="">
                </div>
                <div style="width: 100%;padding: 20px 10px;" v-if="activeName=='second'&&selectedx=='asx'">
                    <img :src="require('../assets/img/server03.jpg')" width="1067px" alt="">
                </div>
                <div style="width: 100%;padding: 20px 10px;" v-if="activeName=='second'&&selectedx=='ns'">
                    <img :src="require('../assets/img/server04.jpg')" width="1067px" alt="">
                </div>
                <div style="width: 100%;padding: 20px 10px;" v-if="activeName=='second'&&selectedx=='pajero'">
                    <img :src="require('../assets/img/server05.jpg')" width="1067px" alt="">
                </div>
                <div style="width: 100%;padding: 20px 10px;" v-if="activeName=='second'&&selectedx=='ev'">
                    <img :src="require('../assets/img/server06.jpg')" width="1067px" alt="">
                </div>
                <div style="width: 100%;padding: 20px 10px;" v-if="activeName=='second'&&selectedx=='phev'">
                    <img :src="require('../assets/img/server07.jpg')" width="1067px" alt="">
                </div>
            </div>
            
            
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    selectedx:'outlander',
    activeName:'second'

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {
},
//方法集合
methods: {

    // 5年／10万km（注：保修・保养的期限都是以保修里程或保修时间哪个先到为准。）
 
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


/* .content {
  width: 79%;
  margin: 0 auto;
} */

.cx{
    display: flex;
    height: 100px;
    /* justify-content: space-between; */
    align-items: start;
}

.cx_item{
    display: flex;
    align-items: start;
    margin-right: 40px;
}

.cx_list{
    height: 200px;
}

/* .manual_div{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
} */

.manual-item1{
    height: 100px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #091d3a;
    width: 287px;
    margin-bottom: 20px;
}




</style>