<!--  -->
<template>
    <div >
        <div><img :src="require('../assets/img/aboutus.jpg')" width="1450px" alt=""></div>
        <div style="width: 80%;font-size: 20px;" class="content">
            <div class="title" >公司介绍</div>
            <div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="公司简介" name="first">
                        <div style="width: 100%;">
                            <img :src="require('../assets/img/aboutus01.png')" width="1043px" alt="">
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="企业理念" name="second" style="font-size: 14px;">
                        <div style="text-align: center;font-size: 16px;font-weight: 800;">以专业、安全、优质的汽车产品，创新汽车生活，守护绿色地球。</div>
                        <div style="line-height: 30px;margin-top: 10px;text-align: center;">
                            昭示着广汽三菱汽车销售有限公司致力于成为社会期待、消费者认可的环境友好企业，并通过持续的创新、不断的进取以及对完美的不懈追求，<br>
                            积极推进节能、环保等技术创新，为顾客创造引领潮流的全新汽车生活体验。这一理念不仅体现了广汽三菱汽车销售有限公司志在守护绿色人居，<br>
                            承担与社会、自然环境和谐统一的企业社会责任，而且进一步明确了广汽三菱汽车销售有限公司未来的发展方向。
                        </div>
                        <div style="width: 100%;">
                            <img :src="require('../assets/img/aboutus02.png')" width="100%" alt="">
                        </div>
                        <div style="text-align: center;font-size: 22px;font-weight: 400;margin-top: 20px;">企业口号 SLOGAN</div>
                        <div style="text-align: center;font-size: 14px;font-weight: 800;margin-top: 10px;">驾驭探索之趣</div>
                        <div style="line-height: 30px;margin-top: 10px;text-align: center;">
                            “驾驭”与三菱“Drive Your Ambition”口号高度切合，既体现了百年三菱的技术和底蕴，又展现出产品在各种路况前行的信心；<br>
                            “趣”代表消费者发现生活中的无限乐趣；整体寓意将为用户创造无限乐趣的汽车生活，并鼓励所有人勇于探索、不断创新。<br>
                            广汽三菱汽车销售有限公司核心用户有着渴望通过全新体验与发现，去获得内心充实与乐趣，不断拓展人生宽度的一面，<br>
                            “驾驭探索之趣”的品牌口号与用户富有活力、勇于探索的特性不谋而合，将对未来营销走向产生指导性的影响。 
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="股东构成" name="three">
                        <div style="width: 100%;margin-top: 40px;">
                            <img :src="require('../assets/img/aboutus03.png')" width="1023px" alt="">
                        </div>
                    </el-tab-pane>
                    
                </el-tabs>
            </div>
            
            
            
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    selectedx:'outlander',
    activeName:'first'

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {
},
//方法集合
methods: {

    // 5年／10万km（注：保修・保养的期限都是以保修里程或保修时间哪个先到为准。）
 
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


/* .content {
  width: 79%;
  margin: 0 auto;
} */

.cx{
    display: flex;
    height: 100px;
    /* justify-content: space-between; */
    align-items: start;
}

.cx_item{
    display: flex;
    align-items: start;
    margin-right: 40px;
}

.cx_list{
    height: 200px;
}

/* .manual_div{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
} */

.manual-item1{
    height: 100px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #091d3a;
    width: 287px;
    margin-bottom: 20px;
}




</style>