<!--  -->
<template>
    <div>
        <div><img :src="require('../assets/img/pdf.jpg')" height="472px" width="1450px" alt=""></div>
        <div class="content">
            <div class="title" >服务手册</div>
            <div class="manual_div">
                <div class="manual-item" v-for="(item,index) in manual" :key="index">
                    <div>
                        <img :src="item.img_url" height="132px" width="287px">
                    </div>
                    <div style="padding: 5px 0px;text-align: center;font-size: 20px;">
                        {{item.name[0]}}
                    </div>
                    <div style="display: flex;padding: 10px;justify-content: space-between;">
                        <el-button round><a :href="'/download/'+item.f1" target="_blank">{{item.name[1]}}</a></el-button>
                        <el-button round><a :href="'/download/'+item.f2" target="_blank">{{item.name[2]}}</a></el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    manual:[
        {
            name:['全新欧蓝德','使用说明书下载','用户手册下载'],
            img_url:require('../assets/img/dg.png'),
            f1:'全新欧蓝德说明书.pdf',
            f2:'全新欧蓝德使用手册.rar',
        },
        {
            name:['阿图柯','保养手册下载','用户手册下载'],
            img_url:require('../assets/img/airtrek.png'),
            f1:'阿图柯保修保养手册.pdf',
            f2:'阿图柯使用手册.zip',
        },
        {
            name:['欧蓝德','使用说明书下载','用户手册下载'],
            img_url:require('../assets/img/outlander.png'),
            f1:'欧蓝德说明书.pdf',
            f2:'欧蓝德使用手册.rar',
        },
        {
            name:['新劲炫','使用说明书下载','用户手册下载'],
            img_url:require('../assets/img/asx.png'),
            f1:'新劲炫说明书.pdf',
            f2:'新劲炫使用手册.rar',
        },
        {
            name:['奕歌','使用说明书下载','用户手册下载'],
            img_url:require('../assets/img/eclipse.png'),
            f1:'奕歌说明书.pdf',
            f2:'奕歌使用手册.rar',
        },
        {
            name:['褀智EV','使用说明书下载','用户手册下载'],
            img_url:require('../assets/img/ev.png'),
            f1:'祺智EV说明书2025.pdf',
            f2:'祺智EV使用手册.rar',
        },
        {
            name:['帕杰罗','使用说明书下载','用户手册下载'],
            img_url:require('../assets/img/2019pajero.png'),
            f1:'帕杰罗说明书.pdf',
            f2:'帕杰罗使用手册.rar',
        }
    ]
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


/* .content {
  width: 79%;
  margin: 0 auto;
} */


/* .manual_div{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
} */

.manual-item{
    width: 287px;
    margin-right: 30px;
}

</style>