<!--  -->
<template>
    <div class="main">
        <img :src="require('../assets/img/l200Big.png')" width="100%" alt="">
        <!-- <div>
            <video-player 
            ref="videoNs"
            class="video-player-box"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
        </div> -->
        <div class="car-desc">
            <div style="font-size: 24px;font-weight: 1200;">
                <img :src="require('../assets/img/l2001.png')" height="63px" width="133px" alt="">
            </div>
            <div style="font-size: 18px;color: #A42525;">全进口&ensp;&ensp;全地形&ensp;&ensp;全生活&ensp;&ensp;越野皮卡</div>
            <div style="font-size:24px;color: #4e4d4d;font-weight: 300;padding: 20px;">整车原装进口&ensp;&ensp;强悍越野性能&ensp;&ensp;奢华舒享空间&ensp;&ensp;全程守护安全</div>
        </div>
        <div class="car-desc">
            <div style="width: 80%;display: flex;justify-content: center;font-size: 14px;">
                <div style="height: 73px;text-align: left;margin-right: 80px;">
                    <div>官方指导价</div>
                    <div><span style="font-size: 20px;font-weight: 800;">27万~30万</span><span style="font-size: 16px;"></span></div>
                    <div style="text-align: right;font-size: 12px;padding-top: 5px;"></div>
                </div>
                <div style="border: 1px solid #999797;"></div>
                <div style="height: 73px;text-align: left;margin:0px 80px;">
                    <div>动力系统</div>
                    <div><span style="font-size: 20px;font-weight: 800;">2.2L MIVEC涡轮增压柴油发动机</span></div>
                    <div style="text-align: right;font-size: 12px;padding-top: 5px;"></div>
                </div>
                <div style="border: 1px solid #999797;"></div>
                <div style="height: 73px;text-align: left;margin:0px 80px;">
                    <div>四驱系统</div>
                    <div><span style="font-size: 20px;font-weight: 800;">超选四驱系统-II+后差速器锁止</span></div>
                    <div style="text-align: right;font-size: 12px;padding-top: 5px;"></div>
                </div>
                <div style="border: 1px solid #999797;"></div>
                <div style="height: 73px;text-align: left;margin-left:80px;">
                    <div>质保服务</div>
                    <div><span style="font-size: 20px;font-weight: 800;">五年十万公里官方质保</span></div>
                    <div style="text-align: right;font-size: 12px;padding-top: 5px;"></div>
                </div>
            </div>
        </div>
        <div class="car-desc">
            <div style="font-size: 24px;font-weight: 1200;">
                车型配置
            </div>
            <div style="box-sizing: border-box;width: 1120px;display: flex;justify-content: center;font-size: 12px;padding-top: 30px;">
                <div style="width: 140px;" :class="selected=='base'?'part-selected':'part'" @click="selected='base'" >基本参数</div>
                <div style="width: 140px;" :class="selected=='power'?'part-selected':'part'" @click="selected='power'">性能参数</div>
                <div style="width: 140px;" :class="selected=='out'?'part-selected':'part'" @click="selected='out'">外饰</div>
                <div style="width: 140px;" :class="selected=='drive'?'part-selected':'part'" @click="selected='drive'">驾驶室</div>
                <div style="width: 140px;" :class="selected=='seat'?'part-selected':'part'" @click="selected='seat'">座椅</div>
                <div style="width: 140px;" :class="selected=='feel'?'part-selected':'part'" @click="selected='feel'">舒适及便利装备</div>
                <div style="width: 140px;" :class="selected=='safe'?'part-selected':'part'" @click="selected='safe'">安全配置</div>
                <div :class="selected=='music'?'part-selected':'part'" @click="selected='music'" style="border-right: 1px solid #4e4d4d;width: 140px;">音响与试听</div>
            </div>
            <div style="width: 90%;height: 600px;display: flex;justify-content: center;margin-top: 5px;">
                <div><img :src="require('../assets/img/l200_'+selected+'.jpg')" width="1120px"></div>
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
//视频组件地址
//https://blog.csdn.net/weixin_38684316/article/details/88070737
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    selected:'',
    playerOptions: {
        // 这里可以配置播放器的选项，如自动播放、循环播放等
        autoplay: false,
        loop: false,
        width:"1450px",
        // 视频源
        sources: [
          {
            type: 'video/mp4', // 视频的类型
            src: require('../assets/img/eclipsecross.mp4') // 视频的路径
          }
        ],
        poster:require('../assets/img/nsBig.jpg')
        // 其他需要的配置...
      }
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    show(e){
        this.selected = e
        // this.show_img = 'airtke_'+'e'
    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.selected = 'base'
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


</style>