<!--  -->
<template>
    <div class="main" style="display:flex;justify-content: center;">
        <div style="width: 800px;" class="detail-content">
            <div id="content_0_detailContent" style="text-align: left;" class="detail-content" deep="4">
 
                <p >
                    广汽三菱App和小程序用户服务协议
                </p>
                <p >
                    本协议仅适用于广汽三菱App和小程序的产品和其中提供的服务。
                </p>
                <p >
                    最近更新日期：2021年6月
                </p>
                <p >
                    如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系
                </p>
                <p >
                    电话：400-977-3030
                </p>
                <p >
                    【提示条款】
                </p>
                <p >
                    欢迎您注册和使用广汽三菱App (以下简称 “本APP”、“App”)和小程序， 使用广汽三菱汽车销售有限公司 (下称“广汽三菱”、“我们”、“我们的”) 本APP和小程序为您提供的各项服务。您确认同意本 《广汽三菱App和小程序用户服务使用协议》 (下称“本协议”) 并完成注 册程序时，本协议在您与广汽三菱间成立并发生法律效力， 同时您正式成为广汽三菱App和小程序用户。 在您注册成为本APP和小程序用户前， 请您务必认真阅读并充分理解本协议各条款，尤其是免除及限制责任条款、费用条款、知识产权条款、法律适用及争议解决条款。免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。 您注册成为本APP和小程序用户， 说明您已充分阅读、理解并同意接受本协议全部内容，与我们达成一致。若您不同意接受本协议的部分或全部内容，请立即停止注册或使用本APP和小程序。 如果您对本协议任一条款有疑问， 可向本APP和小程序在线客服进行咨询。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第一条协议的签约和变更
                </p>
                <p >
                    1.1本协议由您与我们共同签署。本协议对您与我们均具有法律效力。
                </p>
                <p >
                    1.2如果本协议发生修改的，我们将以App和小程序推送通知、弹窗提示或在相关页面发布公告的方式来通知您，修改后的内容一旦以任何形式公布在本APP和小程序上即生效， 并取代此前相关内容， 您应不时关注本APP和小程序公告、通知、弹窗提示及协议相关内容的变动。如您不同意修改后的协议，您有权停止使用本APP和小程序。如您在本协议修改后继续使用我们提供的服务的，即视为您已充分阅读、理解并同意修改后的协议，愿意接受修改后的协议的约束。
                </p>
                <p >
                    1.3除本协议以外，本APP和小程序各单项服务协议及其他我们已经发布的或将来可能发布的各类规则， 包括但不限于隐私政策等，均视为本协议的补充协议，作为本协议不可分割的组成部分，与本协议具有同等法律效力。请您及时关注并阅读相关协议。除另行明确外， 本APP和小程序提供的任何服务均受本协议约束，本APP和小程序的未来版本、更新或其他变更将受到本协议的约束。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    </p><ul>
                        <li>
                            服务的内容和方式
                        </li>
                    </ul>
                <p></p>
                <p >
                    2.1一旦您注册成为本APP和小程序用户， 我们将根据您的需求为您提供包括但不限于车辆控制、车辆服务、商品购买等服务。
                </p>
                <p >
                    2.2根据互联网技术的发展，我们致力于不断提升您的用户体验，并将在未来向您提供更多优质、高效的服务。本APP和小程序作为平台， 我们不保证其提供的服务一定满足您的全部需求。鉴于网络服务的特殊性， 为保证本APP和小程序及服务的安全性和功能一致性，您同意我们有权不经通知随时修改、中断或终止某项服务。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    </p><ul>
                        <li>
                            帐号的注册
                        </li>
                    </ul>
                <p></p>
                <p >
                    3.1在您注册本APP和小程序之前， 您应当确保并承诺您具有中华人民共和国法律规定的与您行为相适应的民事行为能力， 否则您及您的监护人应依照法律规定承担由此而导致的一切后果。
                </p>
                <p >
                    3.2您有权选择是否成为本APP和小程序的注册用户。如果您选择成为本APP和小程序注册用户，则应：
                </p>
                <p >
                    3.2.1按照App和小程序的提示及要求填写或提供资料、信息， 并确 保资料、信息的真实性、准确性、完整性、有效性和合法性。如果您提供的注册资料是不合法、不真实、不准确或不完整的，您需自行承担因此引起的相应责任及后果，并且我们保留终止您使用本APP和小程序各项服务的权利。如果您的资料、信息发生变化，您应及时更改。由于您未及时更新您的注册信息、资料所引发的不利后果，由您自行承担。
                </p>
                <p >
                    3.2.2您一旦注册成功， 即成为本APP和小程序的用户， 您可以自行创建、修改用户名和昵称。用户名和昵称的使用应遵守法律法规并符合网络道德。用户名和昵称中不能含有任何侮辱、诽谤或暴力等侵害他人合法权益或违反公序良俗的措辞，否则，我们有权随时限制或禁止您使用该帐号，甚至注销该帐号。
                </p>
                <p >
                    3.3注册成功后，您将创建一个帐号和密码。您应当采取合理措施维护帐号和密码的安全。因您自身原因导致帐号或密码泄露而造成的法律后果由您自行承担。如您发现他人冒用、盗用您的账号或存在其他安全漏洞的情况，您应立即联系我们。由于我们对您的的请求采取行动需要合理的时间，如我们未在合理时间内采取有效措施，导致您损失扩大的，我们将就扩大的损失部分承担责任，但您应对我们采取行动之前由此用户名和密码登入系统后发生的所 有活动和事件负责，并独自承担由此产生的直接或间接的法律责任；我们因此遭受损失的，您也应当一并赔偿。
                </p>
                <p >
                    3.4若您的帐号或密码遗失，您可通过注册信息重置密码，以手机号码注册的，您可以凭借手机号码找回原密码。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第四条 服务的获取和开通
                </p>
                <p >
                    4.1您可获得的服务取决于您账户的权限。
                </p>
                <p >
                    4.2您可以通过提供购车时预留的手机号码、上传身份证等方式将车辆绑定您的注册帐户，并开通和获得相关服务。在您未将注册帐户绑定车辆之前，您可能无法使用某些特定的服务。
                </p>
                <p >
                    4.3 当您将车辆转让给他人时，请在转让前解除您注册帐户与车辆的绑定。由于您未及时解除帐户而产生的个人信息泄露或因您故意或疏忽造成的个人信息泄露，以及由此引发的任何损失，由您自行承担责任。
                </p>
                <p >
                    4.4 当您解除注册帐户与车辆的绑定后，您仍可以获得除车辆控制外的其他服务。
                </p>
                <p >
                    4.5在您再次购买适用本APP和小程序的汽车时， 您仍可以使用有效的账户进行登录并再次绑定车辆，以获得相关服务。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第五条 费用与支付方式
                </p>
                <p >
                    5.1本APP和小程序提供的商品和服务支持使用在线支付的方式进行购买。
                </p>
                <p >
                    5.2您可以通过支付宝/微信完成在线支付。若由于网络拥堵中断、支付平台或您账户余额不足等原因而导致的迟延付款或未付款，我们不承担任何责任。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第六条 服务限制和免责声明
                </p>
                <p >
                    我们仅对中国大陆地区提供服务。我们提供的服务的效率及质量可能受一些不可控因素的影响，如：您车辆电气系统的设计及构造、您车内重要部件损坏，天气、山地、高楼、隧道、政府行为、战争、暴动、罢工、市政停电、公用设施损坏、运营商政策变动、无线通讯网络无法使用、 行车事故、网络拥堵、黑客攻击等。对于由前述非我们可控因素所造成的服务延误或中断，我们不承担相应责任。
                </p>
                <p >
                    6.1我们提供的相关服务信息均来自于我们可以获得的最新信息，但局限于某些信息更新的滞后性，该信息可能存 在某些差错或遗漏。对于因此而给您造成的损失，您不得主张我们在提供信息时存在法律上的瑕疵，且不得要求我们承担赔偿责任。
                </p>
                <p >
                    6.2请您在日常行驶中务必遵守国内的交通及道路法律法规，确保安全合法地行车，否则您应自行承担因此遭受的损失。
                </p>
                <p >
                    6.3如果您在您的车辆上自行安装任何软、硬件设备，或者对您的车辆进行任何改装或改造，或者自行对车载终端软件进行任何的修改， 均可能导致我们提供的服务无法正常运行，我们对您由此产生的损害或损失不承担任何责任。
                </p>
                <p >
                    6.4您理解并同意我们通过本APP和小程序推送的相关信息，包括但不限于汽车保养通知、车辆使用资讯等均仅为我们向您提供的建议， 我们不能确保该等建议的准确性、及时性，您应当自行确认该等建议的准确性、及时性，我们不对由 该等建议产生的一切纠纷承担任何责任。如果您不需要我们提供上述信息，您可以关闭本APP和小程序的上述推送功能。
                </p>
                <p >
                    6.5若您需要使用蓝牙功能时，您需要打开手机蓝牙功能，并在手机系统设置里面允许联系人和通话记录的同步权限； 我们可能需要访问您的通讯录，通话记录，以及手机歌曲。我们将对您的个人电话通讯录、通话记录以及手机歌曲进行保密。您明确授权我们出于提供蓝牙服务功能的目的访问并储存上述数据。您可以随时关闭手机蓝牙功能，并修改同步权限。
                </p>
                <p >
                    6.6对于地图导航功能，仅供您出行参考，具体情况请参考实际路况。在行车途中，为了您的安全，请不要操作导航，避免引起危险。对于驾车途中操作导航带来的一切风 险或后果，我们不承担任何责任。
                </p>
                <p >
                    6.7您理解并同意，通过账号授权方式，导致其他非车主 使用您的车辆所带来的一切风险，我们不承担任何责任。
                </p>
                <p >
                    6.8我们采用严格的技术手段及人工甄别的方式确保您的隐私安全， 但因您本人原因导致的个人信息安全事件所引发的任何损失，由您自行承担不利后果。
                </p>
                <p >
                    6.9我们合作的第三方服务商与我们为互相独立的实体。 我们仅作为您或您的车辆与第三方服务商之间的联络桥梁。我们无法担保所有的第三方服务商所提供的产品和服务的质量，也不承担由于第三方服务商提供的产品和服务质量问题所产生的责任。如在本APP和小程序上标明或以其他方式表明相关产品或服务系由第三方服务商提供的，您在使用该产品或服务过程中如有疑问或发生纠纷，请您与第三方协商解决。
                </p>
                <p >
                    6.10如果4S店维修人员，或者广汽三菱App和小程序售后人员需要进入车辆，设置调试车辆相关的模块功能，通过车辆工程模式，车辆屏幕 GPS、功放等功能可以提供给4S店人员或者广汽三菱App和小程序售后人员使用。您需要授权售后人员进入App和小程序。需要注意的是，对4S店人员操作导致的问题， 我们不承担任何责任。
                </p>
                <p >
                    6.11如您将App和小程序用于本合同之外的目的， 包括但不限于下列任一行为：
                </p>
                <p >
                    </p><ul>
                        <li>
                            对App和小程序进行反向工程、反向汇编、反向编译， 或者以其他方式尝试发现App和小程序的源代码；
                        </li>
                        <li>
                            对我们拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像 站点等；
                        </li>
                        <li>
                            对App和小程序或者App和小程序运行过程中释放到任何终端内存中的数据、App和小程序运行过程中客户端与服务器端的交互数据， 以及App和小程序运行所必需的系统数据， 进行复制、修改、增加、 删除、挂接运行或创作任何衍生作品， 形式包括但不限于使用插件、外挂或非经我们授权的第三方工具接入App和小程序及相关系统；
                        </li>
                        <li>
                            通过修改或伪造App和小程序运行中的指令、数据， 增加、删减、变动App和小程序的功能或运行效果， 或者将用于上述用途的App和小程序、方法进行运营或向公众传播，无论这些行为是否为商业目的；
                        </li>
                    </ul>
                <p></p>
                <p >
                    由此产生的通讯费用我们将不再支付，由此给我们造成损害的，您须承担相应责任。
                </p>
                <p >
                    本协议依据国家相关法律法规规章制定，您同意严格遵守以下义务：
                </p>
                <p >
                    </p><ul>
                        <li>
                            不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
                        </li>
                        <li>
                            不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、淫秽的、不文明的等信息资料；
                        </li>
                        <li>
                            不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
                        </li>
                        <li>
                            不得利用本APP和小程序从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
                        </li>
                        <li>
                            不得干扰本APP和小程序的正常运转，不得侵入本APP和小程序及国家计算机信息系统；不得教唆他人从事本条所禁止的行为；
                        </li>
                        <li>
                            不得利用在本APP和小程序注册的账户进行牟利性经营活动；
                        </li>
                        <li>
                            您确保您对通过本APP和小程序上传的文字、图片、视频、音频、多媒体资料或其他内容拥有相应合法的权利， 并承诺上述文字、图片、视频、音频、多媒体资料或其他内容，不侵犯任何第三方的肖像权、名誉权、著作权、商标权等权利；不违反您与第三方所签订的对您有约束力的法律文件的规定。
                        </li>
                    </ul>
                <p></p>
                <p >
                    6.12您在本APP和小程序以各种形式发布的一切信息， 均应符合国家法律法规等相关规定及本协议相关规定，符合社会公序良俗，并不侵犯任何第三方主体的合法权益。我们有权随时检查您所上传的内容，如果发现您上传的内容不符合前述规定，我们有权随时删除、屏蔽您所上传的内容。本APP和小程序的系统记录有可能作为您违反法律的证据。
                </p>
                <p >
                    6.13本APP和小程序包含其他用户所提供的信息内容， 如您使用其他用户的内容或与其他用户进行互动产生纠纷的，您应自行妥善解决该等纠纷，并承担相应的法律责任。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第七条 用户信息的收集、使用与保护
                </p>
                <p >
                    7.1您在本APP和小程序进行注册、绑定、下单购物、发表言论、参加活动等行为时， 涉及您的真实姓名/名称、通信地址、联系电话、电子邮箱、订单详情、评价或反馈、投诉内容、cookies等个人信息， 我们为了向您提供App和小程序内的各项服务，包括车辆服务，交易、配送、售后及客户服务，社区互动服务，开展活动，完成良好的顾客体验等，我们需要收集您的相关个人信息，并出于提供服务的目的进行处理。
                </p>
                <p >
                    7.2我们将对其中涉及您的个人信息予以严格保密。我们会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用、未经授权取阅披露、更改或销毁。为了保障您个人信息的安全及满足您的备份需要，我们可能将您的个人信息存储到我们在中国的服务器上。
                </p>
                <p >
                    7.3我们将在您使用本APP和小程序并接受我们服务期间内保留您的个人信息，除非法律要求或允许在更长的期间内保留该等信息。我们保证除下列情况外不对外公开或向任何第三方提供您的个人信息：
                </p>
                <p >
                    </p><ul>
                        <li>
                            事先获得您的明确授权；
                        </li>
                        <li>
                            为向您提供服务或保护您的合法权利所必须；
                        </li>
                        <li>
                            为履行法律义务；
                        </li>
                        <li>
                            本协议或其他条款另有约定。
                        </li>
                    </ul>
                <p></p>
                <p >
                    请仔细阅读并理解本APP和小程序隐私政策， 该政策适用于您使用本APP和小程序提供的任何服务。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第八条 对于通知送达的声明
                </p>
                <p >
                    您应确保您提供给我们的联系方式 (手机号码、电子邮件 地址) 是能有效与您取得联系的联系方式。我们在服务过程中， 将可能通过发送短信、电子邮件、App和小程序通知等方式向您送达各类通知。 因您未提供正确、真实、有效的联系 方式造成的后果，由您自行承担，与我们无关。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第九条 知识产权保护
                </p>
                <p >
                    9.1与我们及App和小程序有关的一切著作权、商标权、专利权、 商业秘密等知识产权，以及与服务相关的所有信息内容， 包括但不限于：文本、图片、图形、音频、视频资料等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护，除涉及第三方授权的软件或技术外，公司享有上述知识产权。
                </p>
                <p >
                    9.2未经公司书面同意， 上述资料均不得在任何媒体直接或间接发布、传播、出于传播或发布目的而改写或再发行，或者被用于其他任何商业目的。若您违反上述约定，公司保留追究上述未经许可行为的权利。
                </p>
                <p >
                    9.3为更好地促进信息分享及宣传推广，您授权我们可在 本APP和小程序及其关联产品和服务上使用您在任何时间段在本APP和小程序发表的任何形式的信息内容 (包括但不限于客户评价、客户咨询、各类话题文章等信息内容) ，以及将上述内容许可给第三方使用。就前述内容引发的争议，您同意我们有权就任何主体侵权而单独提起诉讼。前述授权并不改变您所发布内容的所有权及知识产权归属，也并不影响您行使对发布内容的合法权利。
                </p>
                <p >
                    9.4未经我们事先书面许可， 您不得自行或授权、协助任何第三方非法抓取本APP和小程序内的信息内容，“非法抓取”是指 采用程序或者非正常浏览等技术手段获取内容数据的行为。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第十条 法律适用、管辖和其他
                </p>
                <p >
                    10.1本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律 (仅为本协议解释之目的，不含香港和澳门特别行政区) 您因使用本APP和小程序而产生的或与之相关的一切争议、权利主张或其他事项， 均适用中华人民共和国法律。
                </p>
                <p >
                    10.2本协议由您与我们所在地长沙经开区签署。因本协议产生或与本协议有关的争议，您可与我们以友好协商的方式予以解决或提交约定协议签署地的人民法院予以裁决。
                </p>
                <p >
                    10.3本协议任意一条被视为废止、无效或不可执行，该条款应视为可分的且不影响本协议其他条款的法律效力。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第十一条 商业活动
                </p>
                <p >
                    您理解并接受，我们可以在本APP和小程序进行下述商业活动：通过电子邮件、客户端、网页或其他合法方式向您发送推广信息或其他相关商业信息。通过增值服务系统或其他方式向您发送相关服务信息或其他信息，其他信息包括但不限于通知信息、宣传信息、广告信息。您可以通过对应的退出机制拒绝接收前述信息。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第十二条 服务的终止
                </p>
                <p >
                    鉴于网络服务的特殊性，为保证本APP和小程序及服务的安全性和功能一致性，您同意我们有权不经向您通知而随时中断或永久地终止本APP和小程序服务之全部或部分。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                <p >
                    第十三条 联系我们
                </p>
                <p >
                    您可以使用以下任何一种方式与我们联系。由我们专业的客服人员为您解答使用中疑问，真诚接待您的来电，并接受您的服务投诉和建议。
                </p>
                <p >
                    服务热线： 400-977-3030
                </p>
                <p >
                    【审慎提醒】 如您完成注册，本协议将立即生效，并构成您与我们及我们经营的广汽三菱App和小程序之间有约束力的法律文件。
                </p>
                <p >
                    <br data-filtered="filtered">
                </p>
                </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类

</style>