<!--  -->
<template>
    <div>
        <div><img :src="require('../assets/img/kv.jpg')" height="472px" width="1450px" alt=""></div>
        <div class="content">
            <div class="title" >售后服务网点查询</div>
            <div class="cx">
                <div class="cx_item">
                    <el-select v-model="province" placeholder="请选择省份">
                        <el-option
                          v-for="(item,index) in options"
                          :key="index"
                          :label="item"
                          :value="item">
                        </el-option>
                      </el-select>
                </div>
                <div class="cx_item">
                    <el-select v-model="city" placeholder="请选择城市">
                        <el-option
                          v-for="item in citys"
                          :key="item.value"
                          :label="item"
                          :value="item">
                        </el-option>
                      </el-select>
                </div>
                <!-- <div class="cx_item">
                    <el-input v-model="input" placeholder="请输入内容"></el-input>
                </div> -->
                <!-- <div class="cx_item">
                    <el-button round>立即查询</el-button>
                </div> -->
            </div>
            <div style="font-size: 18px;padding-bottom: 20px;">
                销售服务网点列表
            </div>
            <!-- <div class="cx_list"></div> -->
            <div class="manual_div">
                <div class="manual-item1" v-for="(item,index) in dlr_show" :key="index">
                    <div style="font-weight: 800;font-size: 16px;">{{index+1}}.{{item.DEALER_NAME}}</div>
                    <div>地址：{{item.ADDRESS}}</div>
                    <div>售后服务热线：{{item.SERVICE_TEL}}</div>
                </div>
                <div style="border: 0px solid #091d3a;" v-if="x>0" class="manual-item1" v-for="item in x" :key="item">
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    options: ['请选择省份','福建省','浙江省','天津市','吉林省','海南省','宁夏回族自治区','广东省','山东省','河南省','陕西省','广西壮族自治区','河北省','青海省','北京市','山西省','上海市','辽宁省','新疆维吾尔自治区','江西省','贵州省','湖北省','湖南省','甘肃省','云南省','黑龙江省','重庆市','四川省','西藏自治区','江苏省','内蒙古自治区','安徽省'],
    province:'请选择省份',
    citys:[],
    city:"",
    dlr_info:[],
    dlr_show:[],
    input:'',
    value:'',
    x:0,//占位

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {
    province(newVal, oldVal) {
      this.city = "请选择城市"
      console.log('User changed',newVal, oldVal);
      this.$axios
          .get("/api/DCMS/common/iclub/MspaceAction/getDealerInfo.json?province="+this.province)
          .then((res) => {
            console.log("res",res)
            try {
                this.dlr_info = res.data
                let i = new RegExp(/^{result:(.*)}/gm)
                let s = i.exec(this.dlr_info)[1] //取出返回字符串中的数组
                //生成JSON格式键名
                s = s.replace(new RegExp("CITY","g"),'"CITY"')
                s = s.replace(new RegExp("ADDRESS","g"),'"ADDRESS"')
                s = s.replace(new RegExp("LONGITUDE","g"),'"LONGITUDE"')
                s = s.replace(new RegExp("DEALER_CODE","g"),'"DEALER_CODE"')
                s = s.replace(new RegExp("PROVINCE","g"),'"PROVINCE"')
                s = s.replace(new RegExp("LATITUDE","g"),'"LATITUDE"')
                s = s.replace(new RegExp("DEALER_SHORTNAME","g"),'"DEALER_SHORTNAME"')
                s = s.replace(new RegExp("DEALER_NAME","g"),'"DEALER_NAME"')
                s = s.replace(new RegExp("SERVICE_TEL","g"),'"SERVICE_TEL"')
                s = s.replace(new RegExp("SOS_TEL","g"),'"SOS_TEL"')
                console.log("xxx",JSON.parse(s))
                this.dlr_info = JSON.parse(s)
                
                this.citys = [...new Set(this.dlr_info.map(r=>r.CITY))]
                console.log("citys",this.citys)
                // this.city = this.dlr_info.filter(res=>{res.})

            } catch (error) {
                console.log("error",error)
                this.$message('数据检索失败');
            }
          })
    },
    city(newVal, oldVal){
        this.dlr_show = this.dlr_info.filter(e=>e.CITY==newVal)
        console.log("dlr_show",this.dlr_show)
        if(this.dlr_show.length%3>0){
            this.x = 3 - this.dlr_show.length%3
        }
        console.log('x',this.x)
         
    }
},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


/* .content {
  width: 79%;
  margin: 0 auto;
} */

.cx{
    display: flex;
    height: 100px;
    /* justify-content: space-between; */
    align-items: start;
}

.cx_item{
    display: flex;
    align-items: start;
    margin-right: 40px;
}

.cx_list{
    height: 200px;
}

/* .manual_div{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
} */

.manual-item1{
    height: 100px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #091d3a;
    width: 287px;
    margin-bottom: 20px;
}




</style>