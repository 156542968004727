<template>
  <div id="app">
    <el-dialog
      :visible.sync="dialogVisible"
      width="600px"
      >
      <div style="font-size: 14px;height: 200px;width: 100%;display: flex;justify-content: center;align-items: center;">
        <div style="width: 40%;text-align: center;"><img :src="require('./assets/img/qrcode-app.jpg')" width="140px" alt=""></div>
        <div style="width: 60%;text-align: center;">
          <div style="font-size: 30px;">下载广汽三菱APP</div>
          <div style="margin-top: 10px;">解锁更多精彩</div>
        </div>
        
      </div>
      <div style="width: 100%;">
        <div style="display: flex;width: 100%;">
          <div style="width: 60%;padding-left: 42px;">开发者信息：广汽三菱汽车销售有限公司</div>
          <div style="width: 40%;"><router-link to="client" tag="a" target="_blank">用户协议</router-link></div>
        </div>
        <div style="display: flex;width: 100%;">
          <div style="width: 60%;padding-left: 42px;">版本号：2.3.9 更新时间：2023-03-01</div>
          <div style="width: 40%;"><router-link to="ys" tag="a" target="_blank">隐私政策</router-link></div>
        </div>
      </div>

    </el-dialog>

    <div class="header">
      <div class="common-header"><router-link to="/"><img src="/img/logo.png" height="23px" width="171px" alt=""></router-link></div>
      <div class="header-title">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <!-- <el-menu-item index="1" disabled>官方商城</el-menu-item> -->
              <el-submenu index="2" >
                <template slot="title">车型</template>
                <div style="background-color: #fff;width: 1500px;height: 180px;display: flex;font-size: 12px;position: absolute;top:0px;left:-200px;z-index:99">
                  <div style="width: 200px;padding: 5px 10px;">
                    <div style="width: 100%;height: 15px;">新能源</div>
                    <div><router-link to="airtrek"><img :src="require('./assets/img/airtrek.png')"  width="180px" alt=""></router-link></div>
                    <div style="text-align: center;font-weight: 600;">阿图柯</div>
                    <div style="text-align: center;">官方指导价：19.98万元起</div>
                  </div>
                  <div style="width: 200px;padding: 5px 10px;">
                    <div style="width: 100%;height: 15px;">燃油车</div>
                    <div><router-link to="outlander2022"><img :src="require('./assets/img/dg.png')"  width="180px" alt=""></router-link></div>
                    <div style="text-align: center;font-weight: 600;">全新欧蓝德</div>
                    <div style="text-align: center;">官方指导价：16.98万元起</div>
                  </div>
                  <div style="width: 200px;padding: 5px 10px;">
                    <div style="width: 100%;height: 15px;"></div>
                    <div><router-link to="outlander"><img :src="require('./assets/img/outlander.png')"  width="180px" alt=""></router-link></div>
                    <div style="text-align: center;font-weight: 600;">欧蓝德</div>
                    <div style="text-align: center;">官方指导价：15.98万元起</div>
                  </div>
                  <div style="width: 200px;padding: 5px 10px;">
                    <div style="width: 100%;height: 15px;"></div>
                    <div><router-link to="asx"><img :src="require('./assets/img/asx.png')"  width="180px" alt=""></router-link></div>
                    <div style="text-align: center;font-weight: 600;">新劲炫</div>
                    <div style="text-align: center;">官方指导价：9.98万元起</div>
                  </div>
                  <div style="width: 200px;padding: 5px 10px;">
                    <div style="width: 100%;height: 15px;"></div>
                    <div><router-link to="ns"><img :src="require('./assets/img/eclipse.png')"  width="180px" alt=""></router-link></div>
                    <div style="text-align: center;font-weight: 600;">奕歌</div>
                    <div style="text-align: center;">官方指导价：12.98万元起</div>
                  </div>
                  <div style="width: 200px;padding: 5px 10px;">
                    <div style="width: 100%;height: 15px;">原装进口</div>
                    <div><router-link to="l200"><img :src="require('./assets/img/l200.png')"  width="180px" alt=""></router-link></div>
                    <div style="text-align: center;font-weight: 600;">L200</div>
                    <div style="text-align: center;">官方预售价：27-30万</div>
                  </div>
                </div>
              </el-submenu>
              <!-- <el-menu-item index="3" disabled>购车</el-menu-item> -->
              <el-submenu index="4">
                  <template slot="title">服务</template>
                  <el-menu-item index="4-1"><router-link to="server">五年十万公里超长质保</router-link></el-menu-item>
                  <el-menu-item index="4-2"><router-link to="manual">服务手册</router-link></el-menu-item>
                  <el-menu-item index="4-3"><router-link to="service">售后服务网点查询</router-link></el-menu-item>
                  <el-menu-item index="4-4"><router-link to="vin">进口车车架号查询</router-link></el-menu-item>
              </el-submenu>
              <!-- <el-menu-item index="5" disabled>品牌活动</el-menu-item> -->
              <el-menu-item index="6"><router-link :to="{ path: '/news', query: { flag: 'carNews',name:'车型新闻'} }">车型新闻</router-link></el-menu-item>
              <el-submenu index="7">
                <template slot="title">发现我们</template>
                <el-menu-item index="7-1"><router-link to="aboutus">关于我们</router-link></el-menu-item>
                <el-menu-item index="7-2"><router-link :to="{ path: '/news', query: { flag: 'enterpriseNews',name:'企业新闻'} }">企业新闻</router-link></el-menu-item>
                <!-- <el-menu-item index="7-3" disabled><router-link to="service">悦享智联</router-link></el-menu-item> -->
                <!-- <el-menu-item index="7-4" disabled><router-link to="vin">下载专区</router-link></el-menu-item> -->
                <!-- <el-menu-item index="7-5" disabled><router-link to="vin">预约参观</router-link></el-menu-item> -->
                <!-- <el-menu-item index="7-6" disabled><router-link to="vin">诚聘英才</router-link></el-menu-item> -->
              </el-submenu>
              <el-menu-item index="8" ><router-link to="Mstory">百年三菱</router-link></el-menu-item>
              <el-menu-item index="9" @click="dialogVisible=true">M-SPACE</el-menu-item>

            </el-menu>
      </div>
  </div>
  
    <div ><router-view/></div>
    <div class="floor">
      <!-- <img :src="require('./assets/img/kv.jpg')" height="472px" width="1450px" alt=""> -->
      <li>&#169;广汽三菱汽车销售有限公司版权所有 &nbsp;<a href="http://beian.miit.gov.cn"
        target="_blank">湘ICP备2024045214号-1</a></li>
    </div>
  </div>
</template>
<script>
// import Map from "@/components/home";
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    dialogVisible:false,
    activeIndex: '4',
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
      }

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
const requireContext = require.context('/public/img', false, /\.(png|jpe?g|svg)$/);
console.log("files:",requireContext.keys().map(requireContext))

// this.images = requireContext.keys().map(requireContext);

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}

</script>


<style lang="scss">
body{
  /* font-size: 68.6458px; */
  font-family: "Microsoft YaHei";
  margin: 0px;
  padding: 0px;
  width: 1466px;
  /* height: 1301px; */
  color:#323232;
  OVERFLOW-Y: auto; 
  OVERFLOW-X:hidden;
}

table {
    border: 1px solid gray;
    border-collapse: collapse;
}

td {
    border: 1px solid gray;
    width: 20%;
    text-align: center;
}

a {
  text-decoration: none;
  color: #2c3e50;
}

.router-link-active {
  text-decoration: none;
  color: #2c3e50;
  font-weight: bold;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.detail-content, .detail-content p {
    font-size: 16px;
    line-height: 28px;
    /* text-indent: 28px; */
}

.manual_div{
    /* width: 100%; */
    /* height: 750px; */
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
}

.header{
    display: flex;
    width: 100%;
    height: 61.34px;
}
.common-header  {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 0.21rem;
    position: relative;
    height: 100%;
    line-height: 0.85rem;
    cursor: pointer;
    margin-left: 3em;
    /* display: inline-block; */
}
.title {
  font-size: 0.5138rem;
  font-weight: lighter;
  margin: 0.35rem 0;
}

.content {
  /* text-align: center; */
  width: 79%;
  margin: 0 auto;
}

.title::before {
  content: '';
  width: 0.04rem;
  height: 0.5138rem;
  display: inline-block;
  background: linear-gradient(#de0000, transparent);
  /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr=#de0000, endColorstr=transparent); */
  position: relative;
  top: 0.1rem;
  margin-right: 0.15rem;
}
.floor{
  margin-top: 20px;
  width: 100%;
  padding: 10px 0px;
  font-size: 12px;
  text-align: center;
}

.main{
  width: 1347px;
  text-align: center;
}

.car-desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
}

.part{
  border: 1px solid #4e4d4d;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 35px;
}

.part-selected{
  border: 1px solid #4e4d4d;
  background-color: #4e4d4d;
  border-right: 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 35px;
}

.el-dialog__header {
    padding: 0px 20px 10px !important;
}

.video-js .vjs-big-play-button {
    font-size: 3em;
    line-height: 1.5em;
    height: 1.5em;
    width: 3em;
    display: block;
    position: absolute;
    top: 50% !important;
    left: 47% !important;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 0.3em;
    transition: all 0.4s;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #fff;
  background-color:#7d7d7d; ;
}

.header-title{
    display: flex;
    align-items: center;
}
</style>
