<!--  -->
<template>
    <div style="background-color: #F8F8F8;" >
        <div v-if="$route.query.flag=='enterpriseNews'"><img :src="require('../assets/img/test31_big.jpg')" width="1349px" alt=""></div>
        <div v-else><img :src="require('../assets/img/test11_big.jpg')" width="1349px" alt=""></div>
        <div style="width: 80%;font-size: 20px;" class="content">
            <div class="title" >{{ $route.query.name }}</div>
            <div style="width: 100%;height: 185px;;display: flex;margin-top: 30px;" v-for="(item,index) in news" :key="index">
                <div style="width: 30%;">
                    <router-link :to="{ path: '/newsinfo', query: { id: item.id} }"><img :src="'/img/'+item.img[0]" height="185px" width="353px" alt=""></router-link>
                </div>
                <div style="width: 70%;height: 100%;;padding-left: 20px;">
                    <div>{{item.title}}</div>
                    <div style="padding-top: 22px;font-size: 16px;color: #848588;">{{item.descx}}</div>
                    <div style="height: 90px;"></div>
                    <div style="font-size: 14px;color: #848588;width: 100%;text-align: right;padding-right: 100px;box-sizing: border-box;">{{item.createtime}}</div>
                </div>
            </div>
            
            
            
            
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
    news:[]

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {
    $route(){
        this.getdata()
    }
},
//方法集合
methods: {

    getdata(){
        console.log('flag:',this.$route.query.flag)
        this.$axios
            .get("/mp/GetWxNewsinfo.action?flag="+this.$route.query.flag)
              .then((res) => {
                
                try {
                    console.log("res",res.data.returnMessage.zt)
                    this.news = res.data.returnMessage.zt.reverse()
                    //轮询一下，把img字符串变成数组
                    this.news = this.news.map(itm=>{
                        itm.img = itm.img.split(",")
                        return itm
                    })
                    console.log("itm.map:",this.news)
                    this.$store.commit({
                        type:'updateNews',
                        name:this.news
                    })
                } catch (error) {
                    console.log("error",error)
                    this.$message('数据检索失败');
                }
              })
    }
 
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.getdata()
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>


/* .content {
  width: 79%;
  margin: 0 auto;
} */

.cx{
    display: flex;
    height: 100px;
    /* justify-content: space-between; */
    align-items: start;
}

.cx_item{
    display: flex;
    align-items: start;
    margin-right: 40px;
}

.cx_list{
    height: 200px;
}

/* .manual_div{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
} */

.manual-item1{
    height: 100px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #091d3a;
    width: 287px;
    margin-bottom: 20px;
}




</style>