import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    News:[],
    SelectNews:{}
  },
  mutations: {
    updateNews(state,payload){
      state.News = payload.name
    },
    filerNews(state,payload){
      state.SelectNews = state.News.filter(item=>item.id==payload.id)
      console.log("state.SelectNews",state.News,state.SelectNews)
    }
  },
  actions: {
  },
  modules: {
  }
})
